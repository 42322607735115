<template>
  <swiper
    :options="swiperOption"
    :pagination="pagination"
    :modules="modules"
    class="wSwiper"
    id="wSwiper"
    :navigation="true"
    :speed="1000"
    :loop="true"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
    <swiper-slide v-for="item in dataList" :key="item.id">
      <div class="slide_left">
        <img
          class="name ani"
          swiper-animate-effect="fadeInLeft"
          swiper-animate-duration="1.3s"
          swiper-animate-delay="0.2s"
          :src="item.name"
          alt=""
        />
        <img
          class="introduce ani"
          :src="item.introduce"
          swiper-animate-effect="fadeInLeft"
          swiper-animate-duration="1.3s"
          swiper-animate-delay="0.2s"
          alt=""
        />
        <img
          class="star ani"
          swiper-animate-effect="fadeInLeft"
          swiper-animate-duration="1.3s"
          swiper-animate-delay="0.2s"
          :src="item.star"
          alt=""
        />
        <img
          class="skill ani"
          :src="item.skill"
          swiper-animate-effect="fadeInLeft"
          swiper-animate-duration="1.3s"
          swiper-animate-delay="0.2s"
          alt=""
        />
        <img
          :src="item.relation"
          class="ani"
          swiper-animate-effect="fadeInLeft"
          swiper-animate-duration="1.3s"
          swiper-animate-delay="0.2s"
          alt=""
        />
      </div>
      <div class="slide_right">
        <img
          :src="item.people"
          alt=""
          class="ani"
          swiper-animate-effect="fadeInLeft"
          swiper-animate-duration="1.3s"
          swiper-animate-delay="0s"
        />
      </div>
    </swiper-slide>
  </swiper>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRaw,
  onBeforeMount,
  onMounted,
  toRefs,
  ref,
  nextTick,
  computed,
  watch,
  watchEffect
} from 'vue'
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
// import required modules
import { Pagination, Navigation, EffectFade } from 'swiper'
import * as swiperAni from '@/util/animate.js'

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide
  },
  props: ['dataList'],
  setup(props, { emit }) {
    const data = reactive({
      dataList: props.dataList.dataList,
      modules: [Pagination, Navigation, EffectFade],
      pagination: {
        clickable: true,
        renderBullet: function (index, className) {
          let arr = props.dataList.dotList
          return '<div class="' + className + '" ><span id="name">' + arr[index] + '</span></div>'
        }
      }
    })
    onMounted(() => {})
    watchEffect(() => {
      data.dataList = props.dataList.dataList
    })

    const onSwiper = (swiper) => {
      swiperAni.swiperAnimateCache(swiper) //隐藏动画元素
      swiperAni.swiperAnimate(swiper) //初始化完成开始动画
    }
    const onSlideChange = (swiper) => {
      // swiper切换的时候执行的方法
      swiperAni.swiperAnimate(swiper) //每个slide开始切换时也运行当前slide动画
    }

    const refData = toRefs(data)
    return {
      onSwiper,
      onSlideChange,
      ...refData
    }
  }
})
</script>
<style lang="less">
.wSwiper {
  width: 1500px !important;
  height: 848px !important;
  margin-top: -22px;
}

/* .swiper-button-next, .swiper-button-prev */
.wSwiper .swiper-button-prev,
.wSwiper .swiper-button-next {
  width: 34px;
  height: 63px;
  color: transparent !important;
  margin-top: 20px;
}
.wSwiper .swiper-button-prev {
  background: url('../assets/images/left_btn.png') no-repeat center;
  background-size: 100% 100%;
  left: 85px;
}
.wSwiper .swiper-button-next {
  background: url('../assets/images/right_btn.png') no-repeat center;
  background-size: 100% 100%;
  right: 101px;
}
.wSwiper .swiper-slide {
  position: relative;
}
.wSwiper .swiper-slide .slide_left {
  position: absolute;
  top: 246px;
  left: 175px;
}
.slide_left .name {
  margin-bottom: 14px;
}
.slide_left .introduce {
  margin-bottom: 40px;
}
.slide_left .star {
  margin-bottom: 25px;
}
.slide_left .skill {
  margin-bottom: 30px;
}
.wSwiper .swiper-slide .slide_left img {
  display: block;
}
.wSwiper .swiper-slide .slide_right {
  position: absolute;
  top: -120px;
  right: 80px;
  z-index: -1;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  top: 243px;
  left: 93%;
  width: 50px;
  height: 100%;
  z-index: 999;
}
.swiper-pagination-bullet {
  width: 21px;
  height: 69px;
  background: url('../assets/images/i.png') no-repeat center;
  position: relative;
  opacity: 1 !important;
}
.swiper-pagination-bullet-active {
  background: url('../assets/images/i_active.png') no-repeat center;
}

#name {
  margin-left: 12px;
  font-size: 15px;
  color: #bb9a61;
  position: absolute;
  left: 20px;
  top: 32px;
  width: 50px;
}
</style>>
