<template>
  <div id="container">
    <swiper
      :direction="'vertical'"
      :modules="modules"
      class="mySwiper"
      :mousewheel="true"
    >
      <swiper-slide class="section0">
        <video id="myVideo" loop muted autoplay>
          <source src="../../assets/images/1.mp4" type="video/mp4" />
        </video>
        <div class="layer">
          <Header :navList="navList" />
          <div class="layer_wrapper">
            <div class="slogan">
              <img src="../../assets/images/slogn.png" alt="" />
            </div>
            <div class="contex_wr">
              <div class="btn_wrapper">
                <a href="javascript:void(0)" class="facebook"></a>
                <ul>
                  <li>
                    <a href="javascript:void(0)" class="google"></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="ios"></a>
                  </li>
                </ul>
              </div>
              <div class="refer_scroll">
                <img src="../../assets/images/more_text.png" alt="" />
                <em></em>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide id="section1" class="section">
        <div class="title">
          <img src="../../assets/images/title2.png" />
        </div>
        <div id="introduce">
          <ul>
            <li
              :class="item.active ? 'active' : ''"
              v-for="item in introduceList"
              :key="item.id"
              @mouseover="handleEnter(item)"
            >
              <a href="javascript:void(0)" :class="item.className"></a>
              <div class="imgContent"><img :src="item.imgUrl" /></div>
            </li>
          </ul>
        </div>
      </swiper-slide>
      <swiper-slide id="section2" class="section">
        <div class="title">
          <img src="../../assets/images/title3.png" />
        </div>
        <div class="show_header">
          <ul>
            <li
              :class="item.active ? 'active' : ''"
              v-for="item in heroList"
              :key="item.id"
              @click="handleSwitch(item)"
            >
              <a href="javascript:void(0)" :class="item.className"></a>
            </li>
          </ul>
        </div>
        <transition name="fade">
          <Hero :dataList="dataLists" :key="active" />
        </transition>
      </swiper-slide>
      <swiper-slide id="section3" class="section">
        <div class="title">
          <img src="../../assets/images/title4.png" />
        </div>
        <div class="iframe_wrapper"></div>
        <swiper
          :effect="'coverflow'"
          :grabCursor="true"
          :centeredSlides="true"
          :slidesPerView="'auto'"
          :coverflowEffect="{
            rotate: 0,
            stretch: 1,
            depth: 900,
            modifier: 1,
            slideShadows: true,
          }"
          :autoplay="{
            delay: 2500,
            disableOnInteraction: false,
          }"
          :navigation="true"
          :loop="true"
          :modules="modulesS"
          class="swiperContent"
        >
          <swiper-slide v-for="(item, index) in showList" :key="index"
            ><img :src="item.imgUrl"
          /></swiper-slide>
        </swiper>
      </swiper-slide>
      <div class="footer">
        <div class="footer_wrapper">
          <div class="footer-right">
            <p>公司地址：香港九龍旺角亞皆老街98號富都大廈2樓1D室</p>
            <p>Copyrights © SHARK INFORMATION LIMITED All Rights Reserved.</p>
            <p>Email：zhougoongsiaccou7@gmail.com</p>
          </div>
        </div>
      </div>
    </swiper>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRaw,
  onBeforeMount,
  onMounted,
  toRefs,
  ref,
  nextTick,
  computed,
  watch,
} from "vue";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
// import required modules
import {
  Pagination,
  Mousewheel,
  Navigation,
  EffectCoverflow,
  Autoplay,
} from "swiper";
import Header from "../../components/header.vue";
import Hero from "../../components/hero.vue";
export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
    Header,
    Hero,
  },
  setup: function () {
    const data = reactive({
      modules: [Mousewheel],
      modulesS: [Navigation, EffectCoverflow, Autoplay],
      navList: [
        {
          id: 0,
          className: "first",
          url: "/",
          active: true,
        },
        {
          id: 1,
          className: "second",
          url: "/news",
          active: false,
        },
        {
          id: 2,
          className: "three",
          url: "",
          active: false,
        },
        {
          id: 3,
          className: "four",
          url: "/storeCenter",
          active: false,
        },
      ],
      showList: [
        {
          imgUrl: require("../../assets/images/0.png"),
        },
        {
          imgUrl: require("../../assets/images/1.png"),
        },
        {
          imgUrl: require("../../assets/images/2.png"),
        },
        {
          imgUrl: require("../../assets/images/3.png"),
        },
        {
          imgUrl: require("../../assets/images/4.png"),
        },
      ],
      introduceList: [
        {
          className: "wei",
          imgUrl: require("../../assets/images/wei.png"),
          id: 0,
          active: true,
        },
        {
          className: "shu",
          imgUrl: require("../../assets/images/shu.png"),
          id: 1,
          active: false,
        },
        {
          className: "wu",
          imgUrl: require("../../assets/images/wu.png"),
          id: 2,
          active: false,
        },
        {
          className: "qun",
          imgUrl: require("../../assets/images/qun.png"),
          id: 3,
          active: false,
        },
      ],
      index: 0,
      dataLists: {
        dotList: ["曹操", "曹仁", "荀彧", "司馬懿", "夏侯惇", "夏侯淵", "張遼"],
        dataList: [
          {
            id: 0,
            name: require("../../assets/images/wei/cc/name.png"),
            skill: require("../../assets/images/wei/cc/skill.png"),
            introduce: require("../../assets/images/wei/cc/introduce.png"),
            people: require("../../assets/images/wei/cc/people.png"),
            relation: require("../../assets/images/wei/cc/relation.png"),
            star: require("../../assets/images/wei/cc/star.png"),
          },
          {
            id: 1,
            name: require("../../assets/images/wei/cr/name.png"),
            skill: require("../../assets/images/wei/cr/skill.png"),
            introduce: require("../../assets/images/wei/cr/introduce.png"),
            people: require("../../assets/images/wei/cr/people.png"),
            relation: require("../../assets/images/wei/cr/relation.png"),
            star: require("../../assets/images/wei/cr/star.png"),
          },
          {
            id: 2,
            name: require("../../assets/images/wei/gh/name.png"),
            skill: require("../../assets/images/wei/gh/skill.png"),
            introduce: require("../../assets/images/wei/gh/introduce.png"),
            people: require("../../assets/images/wei/gh/people.png"),
            relation: require("../../assets/images/wei/gh/relation.png"),
            star: require("../../assets/images/wei/gh/star.png"),
          },
          {
            id: 3,
            name: require("../../assets/images/wei/smy/name.png"),
            skill: require("../../assets/images/wei/smy/skill.png"),
            introduce: require("../../assets/images/wei/smy/introduce.png"),
            people: require("../../assets/images/wei/smy/people.png"),
            relation: require("../../assets/images/wei/smy/relation.png"),
            star: require("../../assets/images/wei/smy/star.png"),
          },
          {
            id: 4,
            name: require("../../assets/images/wei/xhd/name.png"),
            skill: require("../../assets/images/wei/xhd/skill.png"),
            introduce: require("../../assets/images/wei/xhd/introduce.png"),
            people: require("../../assets/images/wei/xhd/people.png"),
            relation: require("../../assets/images/wei/xhd/relation.png"),
            star: require("../../assets/images/wei/xhd/star.png"),
          },
          {
            id: 5,
            name: require("../../assets/images/wei/xhy/name.png"),
            skill: require("../../assets/images/wei/xhy/skill.png"),
            introduce: require("../../assets/images/wei/xhy/introduce.png"),
            people: require("../../assets/images/wei/xhy/people.png"),
            relation: require("../../assets/images/wei/xhy/relation.png"),
            star: require("../../assets/images/wei/xhy/star.png"),
          },
          {
            id: 6,
            name: require("../../assets/images/wei/zl/name.png"),
            skill: require("../../assets/images/wei/zl/skill.png"),
            introduce: require("../../assets/images/wei/zl/introduce.png"),
            people: require("../../assets/images/wei/zl/people.png"),
            relation: require("../../assets/images/wei/zl/relation.png"),
            star: require("../../assets/images/wei/zl/star.png"),
          },
        ],
      },
      heroList: [
        {
          id: 0,
          className: "wei",
          active: true,
          dataLists: {
            dotList: [
              "曹操",
              "曹仁",
              "荀彧",
              "司馬懿",
              "夏侯惇",
              "夏侯淵",
              "張遼",
            ],
            dataList: [
              {
                id: 0,
                name: require("../../assets/images/wei/cc/name.png"),
                skill: require("../../assets/images/wei/cc/skill.png"),
                introduce: require("../../assets/images/wei/cc/introduce.png"),
                people: require("../../assets/images/wei/cc/people.png"),
                relation: require("../../assets/images/wei/cc/relation.png"),
                star: require("../../assets/images/wei/cc/star.png"),
              },
              {
                id: 1,
                name: require("../../assets/images/wei/cr/name.png"),
                skill: require("../../assets/images/wei/cr/skill.png"),
                introduce: require("../../assets/images/wei/cr/introduce.png"),
                people: require("../../assets/images/wei/cr/people.png"),
                relation: require("../../assets/images/wei/cr/relation.png"),
                star: require("../../assets/images/wei/cr/star.png"),
              },
              {
                id: 2,
                name: require("../../assets/images/wei/gh/name.png"),
                skill: require("../../assets/images/wei/gh/skill.png"),
                introduce: require("../../assets/images/wei/gh/introduce.png"),
                people: require("../../assets/images/wei/gh/people.png"),
                relation: require("../../assets/images/wei/gh/relation.png"),
                star: require("../../assets/images/wei/gh/star.png"),
              },
              {
                id: 3,
                name: require("../../assets/images/wei/smy/name.png"),
                skill: require("../../assets/images/wei/smy/skill.png"),
                introduce: require("../../assets/images/wei/smy/introduce.png"),
                people: require("../../assets/images/wei/smy/people.png"),
                relation: require("../../assets/images/wei/smy/relation.png"),
                star: require("../../assets/images/wei/smy/star.png"),
              },
              {
                id: 4,
                name: require("../../assets/images/wei/xhd/name.png"),
                skill: require("../../assets/images/wei/xhd/skill.png"),
                introduce: require("../../assets/images/wei/xhd/introduce.png"),
                people: require("../../assets/images/wei/xhd/people.png"),
                relation: require("../../assets/images/wei/xhd/relation.png"),
                star: require("../../assets/images/wei/xhd/star.png"),
              },
              {
                id: 5,
                name: require("../../assets/images/wei/xhy/name.png"),
                skill: require("../../assets/images/wei/xhy/skill.png"),
                introduce: require("../../assets/images/wei/xhy/introduce.png"),
                people: require("../../assets/images/wei/xhy/people.png"),
                relation: require("../../assets/images/wei/xhy/relation.png"),
                star: require("../../assets/images/wei/xhy/star.png"),
              },
              {
                id: 6,
                name: require("../../assets/images/wei/zl/name.png"),
                skill: require("../../assets/images/wei/zl/skill.png"),
                introduce: require("../../assets/images/wei/zl/introduce.png"),
                people: require("../../assets/images/wei/zl/people.png"),
                relation: require("../../assets/images/wei/zl/relation.png"),
                star: require("../../assets/images/wei/zl/star.png"),
              },
            ],
          },
        },
        {
          id: 1,
          className: "shu",
          active: false,
          dataLists: {
            dotList: [
              "黃忠",
              "姜維",
              "劉備",
              "馬超",
              "關羽",
              "諸葛亮",
              "張飛",
              "趙雲",
            ],
            dataList: [
              {
                id: 0,
                name: require("../../assets/images/shu/huang/name.png"),
                skill: require("../../assets/images/shu/huang/skill.png"),
                introduce: require("../../assets/images/shu/huang/introduce.png"),
                people: require("../../assets/images/shu/huang/people.png"),
                relation: require("../../assets/images/shu/huang/relation.png"),
                star: require("../../assets/images/shu/huang/star.png"),
              },
              {
                id: 1,
                name: require("../../assets/images/shu/jiang/name.png"),
                skill: require("../../assets/images/shu/jiang/skill.png"),
                introduce: require("../../assets/images/shu/jiang/introduce.png"),
                people: require("../../assets/images/shu/jiang/people.png"),
                relation: require("../../assets/images/shu/jiang/relation.png"),
                star: require("../../assets/images/shu/jiang/star.png"),
              },
              {
                id: 2,
                name: require("../../assets/images/shu/liu/name.png"),
                skill: require("../../assets/images/shu/liu/skill.png"),
                introduce: require("../../assets/images/shu/liu/introduce.png"),
                people: require("../../assets/images/shu/liu/people.png"),
                relation: require("../../assets/images/shu/liu/relation.png"),
                star: require("../../assets/images/shu/liu/star.png"),
              },
              {
                id: 3,
                name: require("../../assets/images/shu/ma/name.png"),
                skill: require("../../assets/images/shu/ma/skill.png"),
                introduce: require("../../assets/images/shu/ma/introduce.png"),
                people: require("../../assets/images/shu/ma/people.png"),
                relation: require("../../assets/images/shu/ma/relation.png"),
                star: require("../../assets/images/shu/ma/star.png"),
              },
              {
                id: 4,
                name: require("../../assets/images/shu/guan/name.png"),
                skill: require("../../assets/images/shu/guan/skill.png"),
                introduce: require("../../assets/images/shu/guan/introduce.png"),
                people: require("../../assets/images/shu/guan/people.png"),
                relation: require("../../assets/images/shu/guan/relation.png"),
                star: require("../../assets/images/shu/guan/star.png"),
              },
              {
                id: 5,
                name: require("../../assets/images/shu/zhu/name.png"),
                skill: require("../../assets/images/shu/zhu/skill.png"),
                introduce: require("../../assets/images/shu/zhu/introduce.png"),
                people: require("../../assets/images/shu/zhu/people.png"),
                relation: require("../../assets/images/shu/zhu/relation.png"),
                star: require("../../assets/images/shu/zhu/star.png"),
              },
              {
                id: 6,
                name: require("../../assets/images/shu/zhang/name.png"),
                skill: require("../../assets/images/shu/zhang/skill.png"),
                introduce: require("../../assets/images/shu/zhang/introduce.png"),
                people: require("../../assets/images/shu/zhang/people.png"),
                relation: require("../../assets/images/shu/zhang/relation.png"),
                star: require("../../assets/images/shu/zhang/star.png"),
              },
              {
                id: 7,
                name: require("../../assets/images/shu/zhao/name.png"),
                skill: require("../../assets/images/shu/zhao/skill.png"),
                introduce: require("../../assets/images/shu/zhao/introduce.png"),
                people: require("../../assets/images/shu/zhao/people.png"),
                relation: require("../../assets/images/shu/zhao/relation.png"),
                star: require("../../assets/images/shu/zhao/star.png"),
              },
            ],
          },
        },
        {
          id: 2,
          className: "wu",
          active: false,
          dataLists: {
            dotList: ["孫權", "周瑜", "小喬", "大喬", "孫策", "甘寧", "黃蓋"],
            dataList: [
              {
                id: 0,
                name: require("../../assets/images/wu/sun/name.png"),
                skill: require("../../assets/images/wu/sun/skill.png"),
                introduce: require("../../assets/images/wu/sun/introduce.png"),
                people: require("../../assets/images/wu/sun/people.png"),
                relation: require("../../assets/images/wu/sun/relation.png"),
                star: require("../../assets/images/wu/sun/star.png"),
              },
              {
                id: 1,
                name: require("../../assets/images/wu/zhou/name.png"),
                skill: require("../../assets/images/wu/zhou/skill.png"),
                introduce: require("../../assets/images/wu/zhou/introduce.png"),
                people: require("../../assets/images/wu/zhou/people.png"),
                relation: require("../../assets/images/wu/zhou/relation.png"),
                star: require("../../assets/images/wu/zhou/star.png"),
              },
              {
                id: 2,
                name: require("../../assets/images/wu/xiao/name.png"),
                skill: require("../../assets/images/wu/xiao/skill.png"),
                introduce: require("../../assets/images/wu/xiao/introduce.png"),
                people: require("../../assets/images/wu/xiao/people.png"),
                relation: require("../../assets/images/wu/xiao/relation.png"),
                star: require("../../assets/images/wu/xiao/star.png"),
              },
              {
                id: 3,
                name: require("../../assets/images/wu/da/name.png"),
                skill: require("../../assets/images/wu/da/skill.png"),
                introduce: require("../../assets/images/wu/da/introduce.png"),
                people: require("../../assets/images/wu/da/people.png"),
                relation: require("../../assets/images/wu/da/relation.png"),
                star: require("../../assets/images/wu/da/star.png"),
              },
              {
                id: 4,
                name: require("../../assets/images/wu/sc/name.png"),
                skill: require("../../assets/images/wu/sc/skill.png"),
                introduce: require("../../assets/images/wu/sc/introduce.png"),
                people: require("../../assets/images/wu/sc/people.png"),
                relation: require("../../assets/images/wu/sc/relation.png"),
                star: require("../../assets/images/wu/sc/star.png"),
              },
              {
                id: 5,
                name: require("../../assets/images/wu/gan/name.png"),
                skill: require("../../assets/images/wu/gan/skill.png"),
                introduce: require("../../assets/images/wu/gan/introduce.png"),
                people: require("../../assets/images/wu/gan/people.png"),
                relation: require("../../assets/images/wu/gan/relation.png"),
                star: require("../../assets/images/wu/gan/star.png"),
              },
              {
                id: 6,
                name: require("../../assets/images/wu/huang/name.png"),
                skill: require("../../assets/images/wu/huang/skill.png"),
                introduce: require("../../assets/images/wu/huang/introduce.png"),
                people: require("../../assets/images/wu/huang/people.png"),
                relation: require("../../assets/images/wu/huang/relation.png"),
                star: require("../../assets/images/wu/huang/star.png"),
              },
            ],
          },
        },
        {
          id: 3,
          className: "qun",
          active: false,
          dataLists: {
            dotList: [
              "董卓",
              "高順",
              "公孫瓚",
              "華佗",
              "李儒",
              "呂布",
              "貂蟬",
              "袁紹",
            ],
            dataList: [
              {
                id: 0,
                name: require("../../assets/images/qun/dong/name.png"),
                skill: require("../../assets/images/qun/dong/skill.png"),
                introduce: require("../../assets/images/qun/dong/introduce.png"),
                people: require("../../assets/images/qun/dong/people.png"),
                relation: require("../../assets/images/qun/dong/relation.png"),
                star: require("../../assets/images/qun/dong/star.png"),
              },
              {
                id: 1,
                name: require("../../assets/images/qun/gao/name.png"),
                skill: require("../../assets/images/qun/gao/skill.png"),
                introduce: require("../../assets/images/qun/gao/introduce.png"),
                people: require("../../assets/images/qun/gao/people.png"),
                relation: require("../../assets/images/qun/gao/relation.png"),
                star: require("../../assets/images/qun/gao/star.png"),
              },
              {
                id: 2,
                name: require("../../assets/images/qun/gong/name.png"),
                skill: require("../../assets/images/qun/gong/skill.png"),
                introduce: require("../../assets/images/qun/gong/introduce.png"),
                people: require("../../assets/images/qun/gong/people.png"),
                relation: require("../../assets/images/qun/gong/relation.png"),
                star: require("../../assets/images/qun/gong/star.png"),
              },
              {
                id: 3,
                name: require("../../assets/images/qun/hua/name.png"),
                skill: require("../../assets/images/qun/hua/skill.png"),
                introduce: require("../../assets/images/qun/hua/introduce.png"),
                people: require("../../assets/images/qun/hua/people.png"),
                relation: require("../../assets/images/qun/hua/relation.png"),
                star: require("../../assets/images/qun/hua/star.png"),
              },
              {
                id: 4,
                name: require("../../assets/images/qun/li/name.png"),
                skill: require("../../assets/images/qun/li/skill.png"),
                introduce: require("../../assets/images/qun/li/introduce.png"),
                people: require("../../assets/images/qun/li/people.png"),
                relation: require("../../assets/images/qun/li/relation.png"),
                star: require("../../assets/images/qun/li/star.png"),
              },
              {
                id: 5,
                name: require("../../assets/images/qun/lv/name.png"),
                skill: require("../../assets/images/qun/lv/skill.png"),
                introduce: require("../../assets/images/qun/lv/introduce.png"),
                people: require("../../assets/images/qun/lv/people.png"),
                relation: require("../../assets/images/qun/lv/relation.png"),
                star: require("../../assets/images/qun/lv/star.png"),
              },
              {
                id: 6,
                name: require("../../assets/images/qun/diao/name.png"),
                skill: require("../../assets/images/qun/diao/skill.png"),
                introduce: require("../../assets/images/qun/diao/introduce.png"),
                people: require("../../assets/images/qun/diao/people.png"),
                relation: require("../../assets/images/qun/diao/relation.png"),
                star: require("../../assets/images/qun/diao/star.png"),
              },
              {
                id: 7,
                name: require("../../assets/images/qun/yuan/name.png"),
                skill: require("../../assets/images/qun/yuan/skill.png"),
                introduce: require("../../assets/images/qun/yuan/introduce.png"),
                people: require("../../assets/images/qun/yuan/people.png"),
                relation: require("../../assets/images/qun/yuan/relation.png"),
                star: require("../../assets/images/qun/yuan/star.png"),
              },
            ],
          },
        },
      ],
      navList: [
        {
          id: 0,
          className: "first",
          url: "/",
          active: true,
        },
        {
          id: 1,
          className: "second",
          url: "/news",
          active: false,
        },
        {
          id: 2,
          className: "three",
          url: "",
          active: false,
        },
        {
          id: 3,
          className: "four",
          url: "/storeCenter",
          active: false,
        },
      ],
      flag: true,
      active: -1,
    });
    const handleClick = (list) => {
      data.navList.map((item) => {
        if (item.id == list.id) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
    };
    const handleEnter = (list) => {
      data.introduceList.map((item) => {
        if (item.id == list.id) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
    };
    const handleSwitch = (list) => {
      data.active++;
      data.dataLists = list.dataLists;
      data.index = list.id;
      data.heroList.map((item) => {
        if (item.id == list.id) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
    };

    const test = (data) => {
      alert(data);
    };
    const refData = toRefs(data);
    return {
      handleClick,
      handleEnter,
      handleSwitch,

      ...refData,
    };
  },
});
</script>
<style>
#container,
#app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}
.swiper {
  width: 100%;
  height: 100%;
}
#myVideo {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-color: black;
  /* in case the video doesn't fit the whole page*/
  /* background-image: our video; */
  background-position: center center;
  background-size: contain;
  object-fit: cover;
  /*cover video background */
  z-index: 3;
}
.layer {
  position: absolute;
  z-index: 4;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  /*
		* Preventing flicker on some browsers
		* See http://stackoverflow.com/a/36671466/1081396  or issue #183
		*/
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.layer_wrapper {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  position: relative;
}
video::-webkit-media-controls {
  display: none !important;
}
#section1 {
  background: url("../../assets/images/bg2.png") no-repeat center;
  background-size: 100% auto;
  overflow: hidden;
  position: relative;
}
.contex_wr {
  width: 1200px;
  position: absolute;
  bottom: 132px;
  left: 50%;
  margin-left: -600px;
}
.btn_wrapper {
  width: 1200px;
  /* margin: 24vh auto 0 auto; */
  text-align: center;
}
.btn_wrapper .facebook {
  display: block;
  width: 260px;
  height: 77px;
  background: url("../../assets/images/facebook.png");
  margin: 0 auto;
}
.btn_wrapper ul {
  width: 620px;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  text-align: center;
}
.btn_wrapper ul li {
  flex: 1;
  list-style-type: none;
  text-align: center;
  align-items: center;
}
.btn_wrapper ul li a {
  display: block;
  width: 260px;
  height: 78px;
  margin: 0 auto;
}
.btn_wrapper ul li .google {
  background: url("../../assets/images/google.png") no-repeat center;
}
.btn_wrapper ul li .ios {
  background: url("../../assets/images/ios.png") no-repeat center;
}
.refer_scroll {
  margin: 0 auto;
  text-align: center;
  position: relative;
  height: 100px;
}
.refer_scroll i {
  display: block;
  width: 62px;
  height: 79px;
  background: url("../../assets/images/mouse.png") no-repeat center;
  background-size: 100%;
  position: relative;
  margin: 0 auto;
}
.refer_scroll em {
  display: block;
  width: 67px;
  height: 50px;
  background: url("../../assets/images/more_arrow.png") no-repeat center;
  position: absolute;
  top: 20px;
  left: 50%;
  margin-left: -33.5px;
  animation: Rolldown 1s ease-in-out alternate infinite;
}
@keyframes Rolldown {
  0% {
    top: 20px;
  }

  100% {
    top: 30px;
  }
}
#section2 {
  background: url("../../assets/images/bg3.png") no-repeat center;
  background-size: 100% auto;
  overflow: hidden;
  position: relative;
}
.show_header {
  width: 1200px;
  margin: 0 auto;
  position: absolute;
  top: 138px;
  left: 50%;
  margin-left: -600px;
  z-index: 999;
}
.show_header ul {
  display: flex;
}
.show_header ul li a {
  display: block;
  width: 122px;
  height: 110px;
}
.show_header ul li a.wei {
  background: url("../../assets/images/wei_btn.png") no-repeat center;
}
.show_header ul li.active a.wei {
  background: url("../../assets/images/wei_btn_active.png") no-repeat center;
}
.show_header ul li a.shu {
  background: url("../../assets/images/shu_btn.png") no-repeat center;
}
.show_header ul li.active a.shu {
  background: url("../../assets/images/shu_btn_active.png") no-repeat center;
}
.show_header ul li a.wu {
  background: url("../../assets/images/wu_btn.png") no-repeat center;
}
.show_header ul li.active a.wu {
  background: url("../../assets/images/wu_btn_active.png") no-repeat center;
}
.show_header ul li a.qun {
  background: url("../../assets/images/qun_btn.png") no-repeat center;
}
.show_header ul li.active a.qun {
  background: url("../../assets/images/qun_btn_active.png") no-repeat center;
}
#introduce {
  width: 1200px;
  margin: 5px auto;
  text-align: center;
}
#introduce ul {
  display: flex;
}
#introduce ul li {
  width: 100px;
  height: 740px;
  overflow: hidden;
  position: relative;
  transition: all 0.8s ease;
}
#introduce ul li.active {
  width: 895px;
  transition: all 0.8s ease;
}

#introduce ul li a {
  display: block;
  width: 100px;
  height: 602px;
  position: absolute;
  top: 138px;
  left: 0;
}
.wei {
  background: url("../../assets/images/wei_t.png") no-repeat center;
}
.shu {
  background: url("../../assets/images/shu_t.png") no-repeat center;
}
.wu {
  background: url("../../assets/images/wu_t.png") no-repeat center;
}
.qun {
  background: url("../../assets/images/qun_t.png") no-repeat center;
}
#introduce ul li div {
  width: 659px;
  height: 740px;
  position: absolute;
  top: -2px;
  left: 100px;
  z-index: 0;
}
#introduce ul li.last {
  position: absolute;
  top: 0;
  right: 0;
}
#section3 {
  background: url("../../assets/images/bg4.png") no-repeat center;
  background-size: 100% auto;
  overflow: hidden;
  position: relative;
}
.swiperContent {
  width: 1481px;
  height: 676px;
  margin: 60px auto 0 auto;
  text-align: center;
}
.iframe_wrapper {
  width: 1143px;
  height: 676px;
  background: url("../../assets/images/iframe.png") no-repeat center;
  background-size: 100% 100%;
  position: absolute;
  top: 127px;
  left: 50%;
  margin-left: -571.5px;
  z-index: 9;
}
/* .swiper-button-next, .swiper-button-prev */
.swiperContent .swiper-button-prev,
.swiperContent .swiper-button-next {
  width: 69px;
  height: 76px;
  color: transparent !important;
}
.swiperContent .swiper-button-prev {
  background: url("../../assets/images/l_left.png") no-repeat center;
  background-size: 100% 100%;
}
.swiperContent .swiper-button-next {
  background: url("../../assets/images/l_right.png") no-repeat center;
  background-size: 100% 100%;
}

.fade-enter-from {
  opacity: 0;
}

.fade-enter-active {
  transition: all 2s ease;
}

.fade-enter-to {
  opacity: 1;
}

.fade-leave-from {
  opacity: 1;
}

.fade-leave-active {
  transition: all 0 ease;
}

.fade-leave-to {
  opacity: 0;
}
.title {
  margin: 8px auto 0;
  text-align: center;
}
.back {
  display: block;
  width: 109px;
  height: 32px;
  background: url("../../assets/images/back.png") no-repeat center;
  background-size: 100%;
  margin-left: 68px;
  margin-top: 30px;
  margin-bottom: 20px;
}
.back:hover {
  background: url("../../assets/images/back_active.png") no-repeat center;
  background-size: 100%;
}
.footer {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 99;
}
</style>
