<template>
  <div class="container">
    <Header :navList="navList" />
    <div class="content">
      <a href="javascript:void(0)" class="top" @click="backTop" v-if="showBtn"></a>
      <div class="con_wrapper">
        <a href="javascript:void(0)" class="back" @click="handleBack"></a>
        <div class="content_wrapper">
          <div class="text_wrapper">
            <div class="title">【亂鬥魏蜀吳】一秒讀懂武將系統</div>
            <p>
              爭奪天下，武將十分重要，在《亂鬥魏蜀吳》中，玩家率麾下的所有武將攻城掠地，爭霸天下，玩家可以通過主頁面“武將”系統進入，瞭解各個武將謀士的屬性，招募並對其進行升級升星，可以有效的幫助玩家提高隊伍的戰鬥力。
            </p>

            <img src="../../assets/images/13/1.jpg" alt="" />
            <p>
              武將獲取<br />
              武將的品質目前分為紫色、橙色、紅色等3種品質，品質主要影響該武將我初始屬性和成長值的高低。<br />
              武將可以通過以下途徑獲得：<br />
              A. 通過招募系統直接招募獲得武將。<br />
              B. 名將歷練關卡，挑戰通關有幾率掉落武將魂石。<br />
              C. 在武將商城中，消耗將魂令購買武將魂石，積累足夠的魂石可招募對應武將。<br />
              D. 各種官方活動，參與有機會獲得稀有神將。<br />
              武將列表<br />
              在主介面下方功能表列中點擊“武將”，即進入武將清單頁面，已經上陣的武將會始終置頂顯示。點擊武將可查看該武將基礎資訊、屬性、技能、羈絆，同時可以對武將進行升級、進階、升星等操作。遊戲中武將定位、陣營、兵種設定，可以通過升級、進階、升星來提升屬性，增加戰力。<br />
              武將羈絆<br />
              每個武將都有特定的羈絆，如果集齊就能啟動羈絆效果，可以提升戰力，增強實力。<br />
              武將培養<br />
              遊戲中佈陣可以上陣5個武將和一個戰車，點擊主介面的陣容，即可上陣五個武將，然後對五個武將進行裝備、升級、升階、升星，後面換將可以直接對裝備、等級、階級進行傳承，無需再進行升級、升階的操作，注意，升星是無法傳承的，更換武將後需要對最新陣容進行升星，如果之前的武將確定無用了，可以去祭壇進行重生，可以返還培養該武將的材料，十分人性化。<br />
              武將回收<br />
              當玩家手裡有多餘沒用的武將或者上陣武將的更新反覆運算時，可以到祭壇中進行分解或者重生武將。<br />
              分解：可分解武將，多餘的武將魂石分解後可以獲得將魂令，將魂令可以在武將商城購買需要的武將魂石。武將品質越高，分解獲得的將魂令越多。<br />
              重生：之前培養的武將如果不再使用，可以進行重生，重生消耗一定的元寶，重生武將返還武將升階材料和升級材料，包括突破丹、經驗書以及魂石碎片。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRaw,
  onBeforeMount,
  onMounted,
  onUnmounted,
  toRefs,
  ref,
  nextTick,
  computed,
  watch,
  watchEffect
} from 'vue'
import Header from '@/components/header.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {
    Header
  },
  props: [],
  setup(props, emit) {
    const router = useRouter()
    const data = reactive({
      navList: [
        {
          id: 0,
          className: 'first',
          url: '/',
          active: false
        },
        {
          id: 1,
          className: 'second',
          url: '/news',
          active: true
        },
        {
          id: 2,
          className: 'three',
          url: '',
          active: false
        },
        {
          id: 3,
          className: 'four',
          url: '/storeCenter',
          active: false
        }
      ],
      showBtn: false
    })
    onMounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    onUnmounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    const handleBack = () => {
      router.push('/news')
    }
    const showbtn = () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

      if (scrollTop > 900) {
        data.showBtn = true
      } else {
        data.showBtn = false
      }
    }
    const backTop = () => {
      var timer = setInterval(function () {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop
        let ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 30)
    }
    const refData = toRefs(data)
    return {
      showbtn,
      backTop,
      handleBack,
      ...refData
    }
  }
})
</script>
<style scoped lang="less">
img {
  width: 100%;
  margin: 20px auto;
}
.container {
  width: 100%;
  height: 100%;
  background: #fff8e9;
  .head {
    width: 100%;
    height: 552px;
    background: url('../../assets/images/head1_01.jpg') no-repeat center;
  }
  .content {
    width: 100%;
    height: 1956px;
    background: url('../../assets/images/content2_02.jpg') no-repeat center;
    background-size: 100% 100%;
    overflow: hidden;
    .con_wrapper {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;

      .content_wrapper {
        width: 1200px;
        height: 100%;
        background: rgba(255, 248, 233, 0.7);
        padding-bottom: 30px;
        overflow: hidden;
        .text_wrapper {
          margin: 10px 96px;
          .title {
            font-size: 33px;
            color: #b08a47;
            text-align: center;
            margin: 42px auto 34px;
          }
          p {
            color: #b08a47;
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }
    .top {
      display: block;
      width: 50px;
      height: 50px;
      background: url('../../assets/images/top.png') no-repeat center;
      background-size: 100%;
      position: fixed;
      bottom: 100px;
      right: 40px;
    }
  }
}
</style>