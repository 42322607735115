<template>
  <div class="container">
    <Header :navList="navList" />
    <div class="content">
      <a href="javascript:void(0)" class="top" @click="backTop" v-if="showBtn"></a>
      <div class="con_wrapper">
        <a href="javascript:void(0)" class="back" @click="handleBack"></a>
        <div class="content_wrapper">
          <div class="text_wrapper">
            <div class="title">【亂鬥魏蜀吳】缺錢缺材料？每日試煉助你一臂之力</div>
            <p>
              策略的設定及兵種的搭配是《亂鬥魏蜀吳》核心玩法，遊戲中提供了各種試煉副本，需要靈活運用策略方能取勝，其中，每日試煉副本是獲得經驗書、書卷、銅錢的重要途徑，考慮上陣武將搭配佈陣，以保順利通關獲取獎勵，下面來看看每日試煉副本吧！<br />
              【開啟條件】<br />
              主公等級達到30級後開啟每日試煉副本，主頁面點擊“校場”-“每日試煉”即可進入玩法。
            </p>
            <img src="../../assets/images/10/1.jpg" alt="" />
            <p>
              【玩法內容】<br />
              每日試煉目前有三個副本：三英戰呂布、黃巾入侵、七擒孟獲<br />
              三英戰呂布：可以獲得大量的經驗書，經驗書是提升武將等級的重要材料，由於現在武將繼承不能繼承等級，重生也只返還部分經驗書，所以前期經驗書是十分需要的。<br />
              黃巾入侵：可以獲得大量的書卷，書卷是太學院學習科技的必要材料，太學院科技是提升戰力的重要途徑，前期會十分稀缺。<br />
              七擒孟獲：該副本可以獲得大量銅錢，前期裝備強化、淬煉等都需要用到大量的銅錢，是十分稀缺的資源。
            </p>
            <img src="../../assets/images/10/2.jpg" alt="" />
            <p>
              規則：<br />
              1. 首次通關或者挑戰失敗不消耗挑戰次數及體力。<br />
              2.
              通關後可以進行掃蕩，掃蕩每日共有5次免費挑戰機會，還可以通過元寶購買次數，貴族等級越高可以購買的次數越多。<br />
              3. 試煉關卡難度越來越高。<br />
              4. 更高難度的試煉需要一定的玩家等級及通關上一難關。
            </p>
            <img src="../../assets/images/10/3.jpg" alt="" />
            <p>
              建議：<br />
              每日掃蕩試煉副本次數有限，那麼選擇哪個比較好呢，前期如果要升級武將，可以適當掃蕩三英戰呂布副本，後期重點建議掃蕩黃巾入侵和七擒孟獲副本，剛開始提升戰力的途徑就是太學院學習科技和強化裝備，需要用到大量的書卷和銅錢，所以每日都要掃蕩哦，還可以消耗元寶增加額外掃蕩次數，成為貴族可以解鎖更多購買次數。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRaw,
  onBeforeMount,
  onMounted,
  onUnmounted,
  toRefs,
  ref,
  nextTick,
  computed,
  watch,
  watchEffect
} from 'vue'
import Header from '@/components/header.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {
    Header
  },
  props: [],
  setup(props, emit) {
    const router = useRouter()
    const data = reactive({
      navList: [
        {
          id: 0,
          className: 'first',
          url: '/',
          active: false
        },
        {
          id: 1,
          className: 'second',
          url: '/news',
          active: true
        },
        {
          id: 2,
          className: 'three',
          url: '',
          active: false
        },
        {
          id: 3,
          className: 'four',
          url: '/storeCenter',
          active: false
        }
      ],
      showBtn: false
    })
    onMounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    onUnmounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    const handleBack = () => {
      router.push('/news')
    }
    const showbtn = () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

      if (scrollTop > 900) {
        data.showBtn = true
      } else {
        data.showBtn = false
      }
    }
    const backTop = () => {
      var timer = setInterval(function () {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop
        let ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 30)
    }
    const refData = toRefs(data)
    return {
      showbtn,
      backTop,
      handleBack,
      ...refData
    }
  }
})
</script>
<style scoped lang="less">
img {
  width: 100%;
  margin: 20px auto;
}
.container {
  width: 100%;
  height: 100%;
  background: #fff8e9;
  .head {
    width: 100%;
    height: 552px;
    background: url('../../assets/images/head1_01.jpg') no-repeat center;
  }
  .content {
    width: 100%;
    height: 3792px;
    background: url('../../assets/images/content22.jpg') no-repeat center;
    background-size: 100% 100%;
    overflow: hidden;
    .con_wrapper {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;

      .content_wrapper {
        width: 1200px;
        height: 100%;
        background: rgba(255, 248, 233, 0.7);
        padding-bottom: 30px;
        overflow: hidden;
        .text_wrapper {
          margin: 10px 96px;
          .title {
            font-size: 33px;
            color: #b08a47;
            text-align: center;
            margin: 42px auto 34px;
          }
          p {
            color: #b08a47;
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }
    .top {
      display: block;
      width: 50px;
      height: 50px;
      background: url('../../assets/images/top.png') no-repeat center;
      background-size: 100%;
      position: fixed;
      bottom: 100px;
      right: 40px;
    }
  }
}
</style>