<template>
  <div class="container">
    <Header :navList="navList" />
    <div class="content">
      <a href="javascript:void(0)" class="top" @click="backTop" v-if="showBtn"></a>
      <div class="con_wrapper">
        <a href="javascript:void(0)" class="back" @click="handleBack"></a>
        <div class="content_wrapper">
          <div class="text_wrapper">
            <div class="title">【亂鬥魏蜀吳】激情國戰玩法詳解</div>
            <p>
              開疆拓土，一呼百應，《亂鬥魏蜀吳》開啟多人線上國戰玩法，想要在遊戲中開疆擴土，智謀與實力很重要！鐵馬金戈蓄勢待發，百萬雄兵嚴陣以待，激情國戰一觸即發，以謀略和武力的配合逐步攻破村鎮、要塞、城市，展現實力，威震四方！<br />
              【攻城掠地 萬人鏖戰】<br />
              創新SLG國戰手游《亂鬥魏蜀吳》擁有即時多人戰略玩法，全新高度自由策略國戰，玩家化身漢末諸侯，招募史詩名將，統禦精銳同盟，征戰四方，激情國戰就要燃！
            </p>
            <img src="../../assets/images/7/1.jpg" alt="" />
            <p>
              輕度全自動國戰開啟，宣戰、集結、攻城，個人連殺、超神全憑謀略，策略對戰方能扭轉乾坤。天下局勢動盪，《亂鬥魏蜀吳》萬人鏖戰引爆PK熱情，主公與敵人鬥智鬥勇，攻城掠地，占地為王，同時，國戰創新加入彈幕玩法，即時線上刷屏加油、吐槽，體現國戰的社交性！
            </p>
            <img src="../../assets/images/7/2.jpg" alt="" />
            <p>
              【玩法規則】<br />
              1. 國王可對任意與本勢力相鄰的勢力宣戰；將軍則可對任意與本勢力相鄰的勢力提議。<br />
              2. 國王許可權大於大臣，若國王宣戰則無視大臣提議。<br />
              3. 若國王未在宣戰時間宣戰，則宣戰期過後根據將軍提議選擇城池進行宣戰。<br />
              4. 多方勢力不可宣戰同一城池，先宣戰的生效，若多勢力同時提議同一城池，則先提議的勢優先。<br />
              5. 若國王和將軍均在宣戰期間無動作，則本勢力在本次國戰無進攻動作。<br />
              6. 國王可在任意城池上留言，留言只有本勢力才可看到。<br />
              7. 進攻方贏得國戰勝利可對敵方城池的城防值照成巨大傷害，若本身城池的城防低於傷害值，則可獲得該城池。<br />
              8. 只有通過國戰的勝負可獲得城池的所有權，單純進行城池的拆補不會對城池的歸屬有任意影響。<br />
              9. 每場國戰每個勢力僅可選擇一個城池進行宣戰，每個城池在一場國戰中也只可被一個勢力宣戰。<br />
              10. 主城是每個勢力的國都，不可被攻打，其餘佔領越多城池，獲得的獎勵越豐富<br />
              強策略向的國戰玩法，遇上兵戈相撞的三國亂世，到底能碰撞出怎樣的火花？烽火狼煙蓄勢待發，此時不戰更待何時？趕緊來《亂鬥魏蜀吳》國戰體驗一番吧！
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRaw,
  onBeforeMount,
  onMounted,
  onUnmounted,
  toRefs,
  ref,
  nextTick,
  computed,
  watch,
  watchEffect
} from 'vue'
import Header from '@/components/header.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {
    Header
  },
  props: [],
  setup(props, emit) {
    const router = useRouter()
    const data = reactive({
      navList: [
        {
          id: 0,
          className: 'first',
          url: '/',
          active: false
        },
        {
          id: 1,
          className: 'second',
          url: '/news',
          active: true
        },
        {
          id: 2,
          className: 'three',
          url: '',
          active: false
        },
        {
          id: 3,
          className: 'four',
          url: '/storeCenter',
          active: false
        }
      ],
      showBtn: false
    })
    onMounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    onUnmounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    const handleBack = () => {
      router.push('/news')
    }
    const showbtn = () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

      if (scrollTop > 900) {
        data.showBtn = true
      } else {
        data.showBtn = false
      }
    }
    const backTop = () => {
      var timer = setInterval(function () {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop
        let ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 30)
    }
    const refData = toRefs(data)
    return {
      showbtn,
      backTop,
      handleBack,
      ...refData
    }
  }
})
</script>
<style scoped lang="less">
img {
  width: 100%;
  margin: 20px auto;
}
.container {
  width: 100%;
  height: 100%;
  background: #fff8e9;
  .head {
    width: 100%;
    height: 552px;
    background: url('../../assets/images/head1_01.jpg') no-repeat center;
  }
  .content {
    width: 100%;
    height: 3792px;
    background: url('../../assets/images/content22.jpg') no-repeat center;
    background-size: 100% 100%;
    overflow: hidden;
    .con_wrapper {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;

      .content_wrapper {
        width: 1200px;
        height: 100%;
        background: rgba(255, 248, 233, 0.7);
        padding-bottom: 30px;
        overflow: hidden;
        .text_wrapper {
          margin: 10px 96px;
          .title {
            font-size: 33px;
            color: #b08a47;
            text-align: center;
            margin: 42px auto 34px;
          }
          p {
            color: #b08a47;
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }
    .top {
      display: block;
      width: 50px;
      height: 50px;
      background: url('../../assets/images/top.png') no-repeat center;
      background-size: 100%;
      position: fixed;
      bottom: 100px;
      right: 40px;
    }
  }
}
</style>