<template>
  <div class="container">
    <div class="head">
      <Header :navList="navList" />
    </div>
    <div class="content">
      <div class="con_wrapper">
        <a href="javascript:void(0)" class="back"></a>
        <div class="content_wrapper">
          <div class="text_wrapper">
            <div class="title">【亂鬥魏蜀吳】FAQ</div>
            <p>基礎問題</p>
            <p>-下載遊戲所需的網路環境是什麼？</p>
            <p>由於下載用戶端較大，建議在Wifi連網環境下進行下載。</p>
            <p>-遊戲掉線怎麼辦？</p>
            <p>
              這種情況一般由網路不穩定、手機存儲空間不足、安裝檔下載不全或者檔缺失等3種情況導致，這種情況切換一下2g/3g/4g/5g/wifi網路後再進入遊戲查看。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRaw,
  onBeforeMount,
  onMounted,
  toRefs,
  ref,
  nextTick,
  computed,
  watch,
  watchEffect
} from 'vue'
import Header from '@/components/header.vue'
export default defineComponent({
  components: {
    Header
  },
  props: [],
  setup(props, emit) {
    const data = reactive({
      navList: [
        {
          id: 0,
          className: 'first',
          url: '/',
          active: false
        },
        {
          id: 1,
          className: 'second',
          url: '/news',
          active: true
        },
        {
          id: 2,
          className: 'three',
          url: '',
          active: false
        },
        {
          id: 3,
          className: 'four',
          url: '',
          active: false
        }
      ]
    })
    onMounted(() => {})

    const refData = toRefs(data)
    return {
      ...refData
    }
  }
})
</script>
<style scoped lang="less">
.container {
  width: 100%;
  height: 100%;
  background: #fff8e9;
  .head {
    width: 100%;
    height: 552px;
    background: url('../../assets/images/head1_01.jpg') no-repeat center;
  }
  .content {
    width: 100%;
    height: 1956px;
    background: url('../../assets/images/content2_02.jpg') no-repeat center;
    background-size: 100% 100%;
    overflow: hidden;
    .con_wrapper {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;

      .content_wrapper {
        width: 1200px;
        height: 100%;
        background: rgba(255, 248, 233, 0.7);
        padding-bottom: 30px;
        overflow: hidden;
        .text_wrapper {
          margin: 10px 96px;
          .title {
            font-size: 33px;
            color: #b08a47;
            text-align: center;
            margin: 42px auto 34px;
          }
          p {
            color: #b08a47;
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }
  }
}
</style>