<template>
  <div class="container">
    <Header :navList="navList" />
    <div class="content">
      <a href="javascript:void(0)" class="top" @click="backTop" v-if="showBtn"></a>
      <div class="con_wrapper">
        <a href="javascript:void(0)" class="back" @click="handleBack"></a>
        <div class="content_wrapper">
          <div class="text_wrapper">
            <div class="title">【亂鬥魏蜀吳】太學院科技優先學習哪個？</div>
            <p>
              《亂鬥魏蜀吳》中太學院可以進行科技投入，對基礎屬性、兵種、國家等三方面進行屬性能力的加成及提升，主公們潛心學習科技從而大幅提升實力，不過前期書卷和元寶有限，不可能所有的科技都投入，下面分享下太學院科技學習攻略。
            </p>
            <img src="../../assets/images/18/1.jpg" alt="" />
            <p>
              主公等級達到20級解鎖太學院系統，主介面點擊【主公】，選擇【太學院】即可進入。<br />
              在學習科技的過程中，首先看看每次可學習科技的次數，太學院中有多個佇列，可同時進行多個佇列學習。初始每次只能學習一個科技，其他佇列需要解鎖，在訂閱特權中購買招募特權和徵收特權，即可解鎖其他佇列，當前最多同時開三個佇列。
            </p>
            <img src="../../assets/images/18/2.jpg" alt="" />
            <p>
              每次學習消耗一定的元寶和書卷，隨著學習次數增加，消耗的元寶數目、書卷會逐步增加，學習一個科技都需要一定的冷卻時間，想直接完成，可以額外話費元寶加快科技研究的速度。<br />
              基礎科技<br />
              攻擊：攻擊力，物理增傷，法術增傷<br />
              防禦：防禦力，物理減傷，法術減傷<br />
              兵力：總兵力，總兵力，總兵力<br />
              由此可見基礎科技主要增加的是主公的攻擊力、防禦力、兵力和增傷減傷效果，新手玩家建議先把第一頁的生命全部點滿，先升級最基礎的，後期變陣的話這些屬性也一定用的上。<br />
              兵種科技<br />
              科技專案分為不同階段，基礎科技等級達到一定數目，可以解鎖兵種科技，兵種科技可以增加相應兵種的攻擊力、物理減傷、法術減傷等。<br />
              目前遊戲中用得較多輸出的是弓兵，可以優先把弓兵的科技點數加滿，騎兵槍兵其次，盾兵最後再升，然後再是騎兵和槍兵，呂布、關羽、太史慈，看自己有什麼將來升級，最後升級盾兵。
            </p>
            <img src="../../assets/images/18/3.jpg" alt="" />
            <p>
              國家科技<br />
              兵種科技等級達到一定的級別可以解鎖國家科技，國家科技可以增加攻擊力、物理減傷、物理增傷、法術增傷、法術減傷等，優先升級隊伍中武將所屬國家最多的那個國家來升級，這樣升級的話性價比是最高的。<br />
              太學院科技在遊戲裡中影響著主公的戰力，想要持續通關副本，在競技場中戰勝對手就需要正確學習科技，增強自身實力，趕緊行動起來吧！
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRaw,
  onBeforeMount,
  onMounted,
  onUnmounted,
  toRefs,
  ref,
  nextTick,
  computed,
  watch,
  watchEffect
} from 'vue'
import Header from '@/components/header.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {
    Header
  },
  props: [],
  setup(props, emit) {
    const router = useRouter()
    const data = reactive({
      navList: [
        {
          id: 0,
          className: 'first',
          url: '/',
          active: false
        },
        {
          id: 1,
          className: 'second',
          url: '/news',
          active: true
        },
        {
          id: 2,
          className: 'three',
          url: '',
          active: false
        },
        {
          id: 3,
          className: 'four',
          url: '/storeCenter',
          active: false
        }
      ],
      showBtn: false
    })
    onMounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    onUnmounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    const handleBack = () => {
      router.push('/news')
    }
    const showbtn = () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

      if (scrollTop > 900) {
        data.showBtn = true
      } else {
        data.showBtn = false
      }
    }
    const backTop = () => {
      var timer = setInterval(function () {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop
        let ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 30)
    }
    const refData = toRefs(data)
    return {
      showbtn,
      backTop,
      handleBack,
      ...refData
    }
  }
})
</script>
<style scoped lang="less">
.container {
  width: 100%;
  height: 100%;
  background: #fff8e9;
  img {
    width: 100%;
    margin: 20px auto;
  }
  .head {
    width: 100%;
    height: 552px;
    background: url('../../assets/images/head1_01.jpg') no-repeat center;
  }
  .content {
    width: 100%;
    height: 3792px;
    background: url('../../assets/images/content22.jpg') no-repeat center;
    background-size: 100% 100%;
    overflow: hidden;
    .con_wrapper {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;

      .content_wrapper {
        width: 1200px;
        height: 100%;
        background: rgba(255, 248, 233, 0.7);
        padding-bottom: 30px;
        overflow: hidden;
        .text_wrapper {
          margin: 10px 96px;
          .title {
            font-size: 33px;
            color: #b08a47;
            text-align: center;
            margin: 42px auto 34px;
          }
          p {
            color: #b08a47;
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }
    .top {
      display: block;
      width: 50px;
      height: 50px;
      background: url('../../assets/images/top.png') no-repeat center;
      background-size: 100%;
      position: fixed;
      bottom: 100px;
      right: 40px;
    }
  }
}
</style>