<template>
  <div class="container">
    <Header :navList="navList" />
    <div class="content">
      <a href="javascript:void(0)" class="top" @click="backTop" v-if="showBtn"></a>
      <div class="con_wrapper">
        <a href="javascript:void(0)" class="back" @click="handleBack"></a>
        <div class="content_wrapper">
          <div class="text_wrapper">
            <div class="title">【亂鬥魏蜀吳】將星覺醒風雲起！武將覺醒玩法</div>
            <p>
              《亂鬥魏蜀吳》新增武將覺醒玩法，武將覺醒需要消耗一定的碎片和覺醒石，那麼覺醒後武將到底會有怎麼樣的變化？下面一起來看看吧~<br />
              武將覺醒最主要的作用就是，開啟【專屬覺醒技能】，技能自然是越多越好，其次是還能提升屬性加成，<br />
              覺醒條件<br />
              主公等級達到50級，武將星級達到6級即可進行武將覺醒，主介面點擊【武將】-【覺醒】即可進入覺醒介面！<br />
              武將覺醒<br />
              1. 武將可以從未覺醒狀態直至覺醒至30級；<br />
              2. 未覺醒狀態到覺醒0級，消耗一定的武將碎片，後續覺醒需要消耗一定量的覺醒石；<br />
            </p>
            <img src="../../assets/images/8/1.jpg" alt="" />
            <p>
              3. 覺醒後，即可啟動專屬覺醒技能，每覺醒10級後使專屬技能威力提升；<br />
              4. 覺醒後，可以提升武將的屬性，不同武將覺醒後提升的屬性不同。<br />
            </p>
            <img src="../../assets/images/8/2.jpg" alt="" />
            <p>
              覺醒後不僅屬性有所提升，還能啟動專屬覺醒技能，戰力提升一大截，想要成就霸業實力很重要，趕緊行動起來吧！
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRaw,
  onBeforeMount,
  onMounted,
  onUnmounted,
  toRefs,
  ref,
  nextTick,
  computed,
  watch,
  watchEffect
} from 'vue'
import Header from '@/components/header.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {
    Header
  },
  props: [],
  setup(props, emit) {
    const router = useRouter()
    const data = reactive({
      navList: [
        {
          id: 0,
          className: 'first',
          url: '/',
          active: false
        },
        {
          id: 1,
          className: 'second',
          url: '/news',
          active: true
        },
        {
          id: 2,
          className: 'three',
          url: '',
          active: false
        },
        {
          id: 3,
          className: 'four',
          url: '/storeCenter',
          active: false
        }
      ],
      showBtn: false
    })
    onMounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    onUnmounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    const handleBack = () => {
      router.push('/news')
    }
    const showbtn = () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

      if (scrollTop > 900) {
        data.showBtn = true
      } else {
        data.showBtn = false
      }
    }
    const backTop = () => {
      var timer = setInterval(function () {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop
        let ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 30)
    }
    const refData = toRefs(data)
    return {
      showbtn,
      backTop,
      handleBack,
      ...refData
    }
  }
})
</script>
<style scoped lang="less">
img {
  width: 100%;
  margin: 20px auto;
}
.container {
  width: 100%;
  height: 100%;
  background: #fff8e9;
  .head {
    width: 100%;
    height: 552px;
    background: url('../../assets/images/head1_01.jpg') no-repeat center;
  }
  .content {
    width: 100%;
    height: 1956px;
    background: url('../../assets/images/content2_02.jpg') no-repeat center;
    background-size: 100% 100%;
    overflow: hidden;
    .con_wrapper {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;
      .back {
        display: block;
        width: 109px;
        height: 32px;
        background: url('../../assets/images/back.png') no-repeat center;
        background-size: 100%;
        margin-left: 68px;
        margin-top: 30px;
        margin-bottom: 20px;
      }
      .back:hover {
        background: url('../../assets/images/back_active.png') no-repeat center;
        background-size: 100%;
      }
      .content_wrapper {
        width: 1200px;
        height: 100%;
        background: rgba(255, 248, 233, 0.7);
        padding-bottom: 30px;
        overflow: hidden;
        .text_wrapper {
          margin: 10px 96px;
          .title {
            font-size: 33px;
            color: #b08a47;
            text-align: center;
            margin: 42px auto 34px;
          }
          p {
            color: #b08a47;
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }
    .top {
      display: block;
      width: 50px;
      height: 50px;
      background: url('../../assets/images/top.png') no-repeat center;
      background-size: 100%;
      position: fixed;
      bottom: 100px;
      right: 40px;
    }
  }
}
</style>