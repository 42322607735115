
import * as VueRouter from 'vue-router';
import Home from '../views/home/index.vue'
import News from '../views/news/index.vue'
import NewsDetail from '../components/newsDetail/index.vue'
import First from '../views/newsDetail/first.vue'
import Second from '../views/newsDetail/second.vue'
import Three from '../views/newsDetail/three.vue'
import Four from '../views/newsDetail/four.vue'
import Five from '../views/newsDetail/five.vue'
import Six from '../views/newsDetail/six.vue'
import Seven from '../views/newsDetail/seven.vue'
import Eight from '../views/newsDetail/eight.vue'
import Nine from '../views/newsDetail/nine.vue'
import Ten from '../views/newsDetail/ten.vue'
import Eleven from '../views/newsDetail/eleven.vue'
import Twelve from '../views/newsDetail/twelve.vue'
import Thirteen from '../views/newsDetail/thirteen.vue'
import Fourteen from '../views/newsDetail/fourteen.vue'
import Fifteen from '../views/newsDetail/fifteen.vue'
import Sixteen from '../views/newsDetail/sixteen.vue'
import Seventeen from '../views/newsDetail/seventeen.vue'
import Eighteen from '../views/newsDetail/eighteen.vue'
import Nineteen from '../views/newsDetail/nineteen.vue'
import Twenty from '../views/newsDetail/twenty.vue'
import TwentyOne from '../views/newsDetail/twentyOne.vue'
import TwentyTwo from '../views/newsDetail/twentyTwo.vue'
import TwentyThree from '../views/newsDetail/twentyThree.vue'
import StoredCenter from '../views/storedCenter/index.vue'

const routes = [
    { path: '/', component: Home },
    { path: '/news', component: News },
    { path: '/newsDetail', component: NewsDetail },
    { path: '/first', component: First },
    { path: '/second', component: Second },
    { path: '/three', component: Three },
    { path: '/four', component: Four },
    { path: '/five', component: Five },
    { path: '/six', component: Six },
    { path: '/seven', component: Seven },
    { path: '/eight', component: Eight },
    { path: '/nine', component: Nine },
    { path: '/ten', component: Ten },
    { path: '/eleven', component: Eleven },
    { path: '/twelve', component: Twelve },
    { path: '/thirteen', component: Thirteen },
    { path: '/fourteen', component: Fourteen },
    { path: '/fifteen', component: Fifteen },
    { path: '/sixteen', component: Sixteen },
    { path: '/seventeen', component: Seventeen },
    { path: '/eighteen', component: Eighteen },
    { path: '/nineteen', component: Nineteen },
    { path: '/twenty', component: Twenty },
    { path: '/twentyOne', component: TwentyOne },
    { path: '/twentyTwo', component: TwentyTwo },
    { path: '/twentyThree', component: TwentyThree },
    { path: '/storeCenter', component: StoredCenter },
]

const router = VueRouter.createRouter({
    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: VueRouter.createWebHashHistory(),
    routes:routes, // `routes: routes` 的缩写
})
router.afterEach((to,from,next) => {
    window.scrollTo(0,0);
    // 或
    // window.scroll(0, 0);
  });
export default router;