<template>
  <div class="container">
    <Header :navList="navList" />
    <div class="content">
      <a href="javascript:void(0)" class="top" @click="backTop" v-if="showBtn"></a>
      <div class="con_wrapper">
        <a href="javascript:void(0)" class="back" @click="handleBack"></a>
        <div class="content_wrapper">
          <div class="text_wrapper">
            <div class="title">【亂鬥魏蜀吳】策略博弈！兵種克制機制曝光</div>
            <p>
              將為魂，兵為器，群雄逐鹿，除了足智多謀的“軍師”，士兵也很重要的，《亂鬥魏蜀吳》為玩家打造出一個兵種相克、策略至上的兵種系統，四大兵種環環相扣，平衡各兵種作戰能力，合理利用兵種排兵佈陣，可以起到意想不到的效果喲！
            </p>
            <p>【弓槍騎盾 兵種相克】</p>
            <p>
              游戲内的兵種總共分為4種：槍兵、弓兵、騎兵、盾兵。相克關係為弓兵克槍兵，槍兵克騎兵，騎兵克盾兵，盾兵克弓兵。玩家可以通過首頁建築“太學院”對兵種進行升級進階，從而提升部隊的戰鬥力。
            </p>
            <img src="../../assets/images/2/1.jpg" alt="" />
            <p>
              具體而言，槍兵，站位可前可後，擁有不俗的輸出和生存能力，進能輸出擊敗敵軍，退能堅守陣地保護後排，是戰場上的萬金油。
              弓兵，站位多為後排，通常作為隊伍的主力輸出，擁有強大的群體傷害。玩家需要保護弓兵進攻，這是通往勝利的不二法寶。
              盾兵作為肉盾軍團，皆為近戰部隊；他們防高血厚，是戰場中吸收傷害的第一道防線，能抗能打，有的還能輔助一手治療，是最完美的隊友。
              騎兵則是突進型的衝鋒部隊，當戰場處於膠著難以突破時，就依靠他們進行突襲，他們站位可前可後，擁有極強的機動性，可以很精准的找到敵軍的薄弱地帶，一舉擊潰敵軍。
            </p>
            <p>【科技助力 兵種加成】</p>
            <p>
              主公進入太學院，研究想要掌握的科技，全方位助力主公提升戰力，其中兵種學習科技可以為相應的兵種進行加成，增加屬性，科技專案分為不同階段，基礎科技等級達到一定數目，可以解鎖兵種科技，兵種科技可以增加相應兵種的攻擊力、物理減傷、法術減傷等。
              所謂養兵千日，用兵一時，合理利用兵種相克的原理搭配培養陣容，走上巔峰之路！
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRaw,
  onBeforeMount,
  onMounted,
  onUnmounted,
  toRefs,
  ref,
  nextTick,
  computed,
  watch,
  watchEffect
} from 'vue'
import Header from '@/components/header.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {
    Header
  },
  props: [],
  setup(props, emit) {
    const router = useRouter()
    const data = reactive({
      navList: [
        {
          id: 0,
          className: 'first',
          url: '/',
          active: false
        },
        {
          id: 1,
          className: 'second',
          url: '/news',
          active: true
        },
        {
          id: 2,
          className: 'three',
          url: '',
          active: false
        },
        {
          id: 3,
          className: 'four',
          url: '/storeCenter',
          active: false
        }
      ],
      showBtn: false
    })
    onMounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    onUnmounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    const handleBack = () => {
      router.push('/news')
    }
    const showbtn = () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

      if (scrollTop > 900) {
        data.showBtn = true
      } else {
        data.showBtn = false
      }
    }
    const backTop = () => {
      var timer = setInterval(function () {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop
        let ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 30)
    }
    const refData = toRefs(data)
    return {
      showbtn,
      backTop,
      handleBack,
      ...refData
    }
  }
})
</script>
<style scoped lang="less">
img {
  width: 100%;
  margin: 20px auto;
}
.container {
  width: 100%;
  height: 100%;
  background: #fff8e9;
  .head {
    width: 100%;
    height: 552px;
    background: url('../../assets/images/head1_01.jpg') no-repeat center;
  }
  .content {
    width: 100%;
    height: 1956px;
    background: url('../../assets/images/content2_02.jpg') no-repeat center;
    background-size: 100% 100%;
    overflow: hidden;
    .con_wrapper {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;

      .content_wrapper {
        width: 1200px;
        height: 100%;
        background: rgba(255, 248, 233, 0.7);
        padding-bottom: 30px;
        overflow: hidden;
        .text_wrapper {
          margin: 10px 96px;
          .title {
            font-size: 33px;
            color: #b08a47;
            text-align: center;
            margin: 42px auto 34px;
          }
          p {
            color: #b08a47;
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }
    .top {
      display: block;
      width: 50px;
      height: 50px;
      background: url('../../assets/images/top.png') no-repeat center;
      background-size: 100%;
      position: fixed;
      bottom: 100px;
      right: 40px;
    }
  }
}
</style>