<template>
  <div class="container">
    <Header :navList="navList" />
    <div class="content">
      <a href="javascript:void(0)" class="top" @click="backTop" v-if="showBtn"></a>
      <div class="con_wrapper">
        <a href="javascript:void(0)" class="back" @click="handleBack"></a>
        <div class="content_wrapper">
          <div class="text_wrapper">
            <div class="title">【亂鬥魏蜀吳】FAQ</div>
            <p>基礎問題</p>
            <p>-下載遊戲所需的網路環境是什麼？</p>
            <p>由於下載用戶端較大，建議在Wifi連網環境下進行下載。</p>
            <p>-遊戲掉線怎麼辦？</p>
            <p>
              這種情況一般由網路不穩定、手機存儲空間不足、安裝檔下載不全或者檔缺失等3種情況導致，這種情況切換一下2g/3g/4g/5g/wifi網路後再進入遊戲查看。
            </p>
            <p>遊戲問題</p>
            <p>-100連抽如何領取？</p>
            <p>進入遊戲後，主角達到15級即可在郵件中領取100連抽寶箱，在背包查看。</p>
            <p>- 禮包碼在哪使用？</p>
            <p>點擊主介面右側【福利】-【兌換碼】，然後在郵箱中領取。</p>
            <p>-如何打開/關閉聲音？</p>
            <p>點擊主介面左上角【頭像】-【遊戲聲音】，就可以打開/關閉聲音。</p>
            <p>Q：怎麼修改主公名稱？</p>
            <p>A：左上角點擊主公圖示，名字處點擊編輯即可修改，改名消耗一定的元寶。</p>
            <p>Q：頭像和邊框名稱？</p>
            <p>
              A：左上角點擊主公圖示-“更換”，即可修改頭像和邊框，頭像可選擇任意已招募的武將，邊框也需要解鎖，啟動對應的戰車即可解鎖邊框。
            </p>
            <p>Q：怎麼更改主頁人物？</p>
            <p>A：任務左邊有刷新按鈕，點擊進入即可選擇新的人物。</p>
            <p>武將相關</p>
            <p>-武將有哪些兵種？</p>
            <p>武將分為4大兵種：騎兵、槍兵、弓兵、盾兵。</p>
            <p>-從哪裡能看到所有武將的技能、羈絆等介紹？</p>
            <p>主介面武將裡可預覽所有武將資訊。</p>
            <p>-武將的品質是怎麼區分的？</p>
            <p>可以通過武將的顏色以及資質區分武將，紫、橙、紅。</p>
            <p>-武將的基本屬性有哪些？</p>
            <p>武將基礎屬性分為總兵力、攻擊、防禦。</p>
            <p>-如何升級武將？</p>
            <p>消耗經驗書即可為武將升級，推圖、商店購買、每日試煉可獲得經驗書。</p>
            <p>-什麼是羈絆？</p>
            <p>每位元武將都有羈絆屬性，當獲得羈絆武將後可在武將羈絆頁中啟動。</p>
            <p>-怎樣遮罩世界發言？</p>
            <p>點擊【發言者頭像】-【拉黑】</p>
            <p>戰鬥相關</p>
            <p>-怒氣有什麼用？</p>
            <p>
              武將的怒氣值上限為1000點，當怒氣達到1000後即可點擊釋放必殺技。普通攻擊獲得少量怒氣，受到傷害獲得一定量怒氣，釋放技能會獲得更多的怒氣。
            </p>
            <p>-某一個關卡打不過了怎麼辦？</p>
            <p>
              如果戰力相差不大，可以多調整佈陣或者更換不同的武將進行嘗試；如果戰力差距大，可以提升武將等級，星級，強化裝備，然後再來嘗試。
            </p>
            <p>-如何提升戰力？</p>
            <p>武將升級/進階/升星/羈絆/裝備/配飾，還有太學院、神器、戰車、戰馬玩法等等；</p>
            <p>玩法相關</p>
            <p>-什麼副本可以掃蕩？</p>
            <p>征戰通關或者每日試煉都可掃蕩。</p>
            <p>-離開同盟再次加入需要多久的冷卻時間？</p>
            <p>主動退出後進入24小時懲罰時間（懲罰時間內無法參與同盟相關玩法），退出10分鐘才能重新加入。</p>
            <p>-為什麼競技場血量跟副本裡顯示不一致？</p>
            <p>所有的PVP玩法血量會翻倍。</p>
            <p>-加好友有什麼用？</p>
            <p>可以贈送和獲贈體力，可以與好友交流探討遊戲心得。</p>
            <p>國戰相關</p>
            <p>Q：怎麼當國王？</p>
            <p>A：首先你要在【王座】中，成爲儲君，然後隔天22點時，儲君將成爲本國國王。</p>
            <p>Q：怎麼宣戰？</p>
            <p>A：國王可以進行宣戰，進入霸業，選中一個想要宣戰的城池，選擇宣戰即可。</p>
            <p>Q：國戰怎麼報名參加？</p>
            <p>
              A：國王宣戰後，玩家可以去郵件-國家裡查看，點擊”立即前往”即可去已宣戰的城池，隨後點擊城池-報名即可進入戰場。
            </p>
            <p>材料相關</p>
            <p>Q：裝備怎麼獲得？</p>
            <p>A：挑戰銅雀台副本，遠征掛機，還有貴族特權禮包以及各種限時活動。</p>
            <p>Q：如何獲得體力？</p>
            <p>
              A：【征戰】介面中，點擊右上方體力圖示後的“+”，即可購買體力，每日首次免費，後續用元寶購買，不同貴族等級次數不同。
              每日三次免費領取體力的機會，午餐：12：00-14：00，晚餐：18：00-20：00，夜宵：21：00-24：00。
              還有各種活動禮包，充值兌換活動。
            </p>
            <p>Q：不用的裝備怎麼辦？</p>
            <p>A：直接到祭壇分解系統進行分解，返還培養材料，獲得高一品質的裝備碎片。</p>
            <p>Q：南山精鐵怎麼獲得？</p>
            <p>A：挑戰銅雀台副本，50層後每層通關都有南山精鐵獎勵，戰役中的強制徵收和遠征掛機也可以獲得南山精鐵。</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRaw,
  onBeforeMount,
  onMounted,
  onUnmounted,
  toRefs,
  ref,
  nextTick,
  computed,
  watch,
  watchEffect
} from 'vue'
import Header from '@/components/header.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {
    Header
  },
  props: [],
  setup(props, emit) {
    const router = useRouter()
    const data = reactive({
      navList: [
        {
          id: 0,
          className: 'first',
          url: '/',
          active: false
        },
        {
          id: 1,
          className: 'second',
          url: '/news',
          active: true
        },
        {
          id: 2,
          className: 'three',
          url: '',
          active: false
        },
        {
          id: 3,
          className: 'four',
          url: '/storeCenter',
          active: false
        }
      ],
      showBtn: false
    })
    onMounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    onUnmounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    const handleBack = () => {
      router.push('/news')
    }
    const showbtn = () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

      if (scrollTop > 900) {
        data.showBtn = true
      } else {
        data.showBtn = false
      }
    }
    const backTop = () => {
      var timer = setInterval(function () {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop
        let ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 30)
    }
    const refData = toRefs(data)
    return {
      showbtn,
      backTop,
      handleBack,
      ...refData
    }
  }
})
</script>
<style scoped lang="less">
.container {
  width: 100%;
  height: 100%;
  background: #fff8e9;
  .head {
    width: 100%;
    height: 552px;
    background: url('../../assets/images/head1_01.jpg') no-repeat center;
  }
  .content {
    width: 100%;
    height: 3792px;
    background: url('../../assets/images/content22.jpg') no-repeat center;
    background-size: 100% 100%;
    overflow: hidden;
    .con_wrapper {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;

      .content_wrapper {
        width: 1200px;
        height: 100%;
        background: rgba(255, 248, 233, 0.7);
        padding-bottom: 30px;
        overflow: hidden;
        .text_wrapper {
          margin: 10px 96px;
          .title {
            font-size: 33px;
            color: #b08a47;
            text-align: center;
            margin: 42px auto 34px;
          }
          p {
            color: #b08a47;
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }
    .top {
      display: block;
      width: 50px;
      height: 50px;
      background: url('../../assets/images/top.png') no-repeat center;
      background-size: 100%;
      position: fixed;
      bottom: 100px;
      right: 40px;
    }
  }
}
</style>