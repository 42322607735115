<template>
  <div class="container">
    <Header :navList="navList" />
    <div class="content">
      <a href="javascript:void(0)" class="top" @click="backTop" v-if="showBtn"></a>
      <div class="con_wrapper">
        <a href="javascript:void(0)" class="back" @click="handleBack"></a>
        <div class="content_wrapper">
          <div class="text_wrapper">
            <div class="title">【亂鬥魏蜀吳】匠心巨制！打造真實策略國戰體驗</div>
            <p>
              《亂鬥魏蜀吳》是一款集策略、養成、熱血PK於一體的輕度SLG休閒國戰卡牌游戲，從卡牌+策略入手，跳出了傳統三國策略遊戲的框架，除了武將的培養，更注重戰術策略，拼的是謀略，讓玩家體驗身臨三國戰場奮勇殺敵的爽快感。
            </p>
            <img src="../../assets/images/9/1.jpg" alt="" />
            <p>
              畫面升級 最炫酷國戰<br />
              風度翩翩諸葛亮，腹黑帥氣司馬懿，三國中耳熟能祥的人物在遊戲中採用全新設定，就連傳統印象中的張飛、典韋都是熱血少年畫風，海量武將意氣風發，炫酷逼人，每個角色都是獨家原創設定，擁有鮮明特點。<br />
              同時，《亂鬥魏蜀吳》擁有宏觀上“萬人國戰”的大場景與微觀上“無雙技能”的細節微操，這些無不考驗了我們精湛的美術功底，遊戲場景大量運用2D畫面，戰鬥技能釋放、城池建築等都刻畫的相當細膩，場景宏大畫面精美，在明亮度、色彩搭配渲染方面都有著不俗的表現。
            </p>
            <img src="../../assets/images/9/2.jpg" alt="" />
            <p>
              無雙武將集結，大殺四方<br />
              《亂鬥魏蜀吳》有近百位三國名將，曹操、劉備、諸葛亮、關羽等均可供玩家差遣，自由招募武將打造最強陣容，血戰沙場，除此之外還有完整的武將進階方法，包括武將進階系統、升級系統、升星系統、技能系統，如此多的培養策略，征戰沙場勢不可擋。<br />
              遊戲中有四大兵種，戲策略性的“兵種相克“玩法，弓、騎、盾、步四大兵種相互克制，每個武將都有特定的類型，近戰、遠程、群攻、控制、爆發等等，比如呂布擁有全場無敵技能、趙雲單體傷害瞬間爆炸，屬性多樣化，玩家可以利用這些特點合理排兵佈陣。
            </p>
            <img src="../../assets/images/9/3.jpg" alt="" />
            <p>
              重溫三國經典 體驗特色PVE<br />
              經典複刻，天下、戰役等玩法重現三國著名戰役，成功挑戰通關可以獲得資源及道具。戰局瞬息萬變，挑戰這些關卡考驗玩家的戰術策略，選擇適當的英雄，知人善用才能成功將其制服。<br />
              征戰天下劇情關卡中有大家耳熟能詳的戰役，虎牢關之戰、赤壁之戰、華容道、漢中爭奪戰、夷陵之戰等章節一一呈現，玩家協同三國名將，強強聯手進入經典劇情關卡，一路過關斬將，重溫豪情三國時代。
            </p>
            <img src="../../assets/images/9/4.jpg" alt="" />
            <p>
              還有“戰役”玩法，經典戰役、華容道、銅雀台等副本是玩家獲得各種資源的重要途徑，玩家可以針對不同的敵人派遣不同的兵種，發揮兵種所長、互相克制，組織不同的策略進行戰鬥，最終左右戰局，獲得所需資源。<br />
              戰車戰馬 助你一臂之力<br />
              三國戰場，戰馬戰車也是必不可少的，關鍵時刻可以助你一臂之力，《亂鬥魏蜀吳》中每個上陣的武將可攜帶一匹戰馬用於提升屬性。玩家通過馬廄消耗一定的材料召喚戰馬，攜帶符文，用於提升屬性。赤兔、的盧、絕影、烏騅.......十四種三國名馬與你一起衝鋒陷陣。
            </p>
            <img src="../../assets/images/9/5.jpg" alt="" />
            <p>
              而戰車也是pk必備神器，玩家可以雇傭一輛戰車上戰場，不同的戰車技能不同，有了他們多樣化的屬性加成，可以精准擊潰敵方勢力。<br />
              萬人國戰ＰＫ 殺敵全憑智謀<br />
              遊戲中有多種PVP玩法，國戰、陣營戰、競技場讓你體會少年專屬PK熱情。三國爭霸，人多才有意思，國戰PK系統深度優化，操作體驗創新設定，兵種克制，讓你的國戰玩出花樣！萬人同屏熱血殺敵，以智謀取勝，激發血戰沙場的豪邁情懷，兵種搭配靈活運用讓戰鬥變成了智謀的角逐，杜絕了氪金碾壓的情況。<br />
              【綜合評測】<br />
              特色武將系統，無雙武將都靜待著你的驅使，兵種相克的靈活運用，配合超強的技能，將帶給你前所未有的戰鬥快感。還有多種PVP國戰、PVE副本玩法，加上跌宕起伏的劇情、宏大的場景，精美的畫面特效都讓本作成為本年度必玩的遊戲。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRaw,
  onBeforeMount,
  onMounted,
  onUnmounted,
  toRefs,
  ref,
  nextTick,
  computed,
  watch,
  watchEffect
} from 'vue'
import Header from '@/components/header.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {
    Header
  },
  props: [],
  setup(props, emit) {
    const router = useRouter()
    const data = reactive({
      navList: [
        {
          id: 0,
          className: 'first',
          url: '/',
          active: false
        },
        {
          id: 1,
          className: 'second',
          url: '/news',
          active: true
        },
        {
          id: 2,
          className: 'three',
          url: '',
          active: false
        },
        {
          id: 3,
          className: 'four',
          url: '/storeCenter',
          active: false
        }
      ],
      showBtn: false
    })
    onMounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    onUnmounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    const handleBack = () => {
      router.push('/news')
    }
    const showbtn = () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

      if (scrollTop > 900) {
        data.showBtn = true
      } else {
        data.showBtn = false
      }
    }
    const backTop = () => {
      var timer = setInterval(function () {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop
        let ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 30)
    }
    const refData = toRefs(data)
    return {
      showbtn,
      backTop,
      handleBack,
      ...refData
    }
  }
})
</script>
<style scoped lang="less">
img {
  width: 100%;
  margin: 20px auto;
}
.container {
  width: 100%;
  height: 100%;
  background: #fff8e9;
  .head {
    width: 100%;
    height: 552px;
    background: url('../../assets/images/head1_01.jpg') no-repeat center;
  }
  .content {
    width: 100%;
    height: 4509px;
    background: url('../../assets/images/content.jpg') no-repeat center;
    background-size: 100% 100%;
    overflow: hidden;
    .con_wrapper {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;

      .content_wrapper {
        width: 1200px;
        height: 100%;
        background: rgba(255, 248, 233, 0.7);
        padding-bottom: 30px;
        overflow: hidden;
        .text_wrapper {
          margin: 10px 96px;
          .title {
            font-size: 33px;
            color: #b08a47;
            text-align: center;
            margin: 42px auto 34px;
          }
          p {
            color: #b08a47;
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }
    .top {
      display: block;
      width: 50px;
      height: 50px;
      background: url('../../assets/images/top.png') no-repeat center;
      background-size: 100%;
      position: fixed;
      bottom: 100px;
      right: 40px;
    }
  }
}
</style>