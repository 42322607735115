<template>
  <div class="container">
    <Header :navList="navList" />
    <div class="content">
      <a href="javascript:void(0)" class="top" @click="backTop" v-if="showBtn"></a>
      <div class="con_wrapper">
        <a href="javascript:void(0)" class="back" @click="handleBack"></a>
        <div class="content_wrapper">
          <div class="text_wrapper">
            <div class="title">【亂鬥魏蜀吳】武將羈絆覺醒最強BUFF</div>
            <p>
              無兄弟，不三國，《亂鬥魏蜀吳》三國武將再續亂世情誼，遊戲推出武將羈絆玩法，武將集結惺惺相惜，激發最強戰鬥力，陣容混搭無限制，征戰天下問鼎中原指日可待！<br />
              【強強聯手 覺醒超強buff】<br />
              英雄惺惺相惜，武將強強聯手，羈絆系統助力武將覺醒超強戰鬥buff!
              《亂鬥魏蜀吳》有豐富的武將養成玩法，每名武將都有獨特的羈絆組合，滿足條件啟動羈絆後將激發強大的屬性加成。由於上陣的武將個數有限，所以建議主公選擇自己陣容的時候，在考慮武將定位的同時，儘量啟動最大數量的羈絆，這樣才能達到最佳作戰效果。
            </p>
            <img src="../../assets/images/19/1.jpg" alt="" />
            <p>
              【多種羈絆 陣容搭配無限制】<br />
              《亂鬥魏蜀吳》武將羈絆有據可依，每個羈絆都融入了三國歷史中一些典故或淵源，陣容搭配觸發羈絆效果可以提升主公實力，每個武將都有三種羈絆，集齊不同的武將可觸發不同的羈絆，羈絆啟動後對實力的加成不同，分別可以增加兵力、防禦、攻擊等，搭配方式多樣化，靈活佈陣。
            </p>
            <img src="../../assets/images/19/2.jpg" alt="" />
            <p>
              運籌帷幄，盡在指尖，《亂鬥魏蜀吳》武將羈絆玩法來襲，何不收集一大波武將提升自己的實力呢？群雄待招，引爆熱血三國戰場，快來集結兵力，激戰群雄吧！
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRaw,
  onBeforeMount,
  onMounted,
  onUnmounted,
  toRefs,
  ref,
  nextTick,
  computed,
  watch,
  watchEffect
} from 'vue'
import Header from '@/components/header.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {
    Header
  },
  props: [],
  setup(props, emit) {
    const router = useRouter()
    const data = reactive({
      navList: [
        {
          id: 0,
          className: 'first',
          url: '/',
          active: false
        },
        {
          id: 1,
          className: 'second',
          url: '/news',
          active: true
        },
        {
          id: 2,
          className: 'three',
          url: '',
          active: false
        },
        {
          id: 3,
          className: 'four',
          url: '/storeCenter',
          active: false
        }
      ],
      showBtn: false
    })
    onMounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    onUnmounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    const handleBack = () => {
      router.push('/news')
    }
    const showbtn = () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

      if (scrollTop > 900) {
        data.showBtn = true
      } else {
        data.showBtn = false
      }
    }
    const backTop = () => {
      var timer = setInterval(function () {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop
        let ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 30)
    }
    const refData = toRefs(data)
    return {
      showbtn,
      backTop,
      handleBack,
      ...refData
    }
  }
})
</script>
<style scoped lang="less">
.container {
  width: 100%;
  height: 100%;
  background: #fff8e9;
  img {
    width: 100%;
    margin: 20px auto;
  }
  .head {
    width: 100%;
    height: 552px;
    background: url('../../assets/images/head1_01.jpg') no-repeat center;
  }
  .content {
    width: 100%;
    height: 1956px;
    background: url('../../assets/images/content2_02.jpg') no-repeat center;
    background-size: 100% 100%;
    overflow: hidden;
    .con_wrapper {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;

      .content_wrapper {
        width: 1200px;
        height: 100%;
        background: rgba(255, 248, 233, 0.7);
        padding-bottom: 30px;
        overflow: hidden;
        .text_wrapper {
          margin: 10px 96px;
          .title {
            font-size: 33px;
            color: #b08a47;
            text-align: center;
            margin: 42px auto 34px;
          }
          p {
            color: #b08a47;
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }
    .top {
      display: block;
      width: 50px;
      height: 50px;
      background: url('../../assets/images/top.png') no-repeat center;
      background-size: 100%;
      position: fixed;
      bottom: 100px;
      right: 40px;
    }
  }
}
</style>