<template>
  <div class="container">
    <Header :navList="navList" />
    <div class="content">
      <a href="javascript:void(0)" class="top" @click="backTop" v-if="showBtn"></a>
      <div class="con_wrapper">
        <a href="javascript:void(0)" class="back" @click="handleBack"></a>
        <div class="content_wrapper">
          <div class="text_wrapper">
            <div class="title">【亂鬥魏蜀吳】巔峰對決！競技場玩法</div>
            <p>
              自古競爭出人才，想要拔得頭籌就來競技場PK，和天下英雄一較高下吧！競技場是《亂鬥魏蜀吳》中的即時PVP玩法，是一個絕對公平的戰場，激鬥天下群雄，排兵佈陣策略至上，巔峰對決一觸即發，只有合理搭配陣容才能擊敗對方。
            </p>
            <p>【最強策略PK 比拼軍事謀略】</p>
            <p>
              無雙技能、兵種搭配，無法操控的戰鬥走向，一切全看陣容搭配組合，競技場是新手玩家歷練的舞臺，也是成就威名展現實力的鬥技場。在這裡與其他玩家切磋，挑戰排名靠前的玩家。<br />
              玩家在主介面點擊“校場”，選擇競技場即可進入競技場PK，激鬥天下群雄。
            </p>
            <img src="../../assets/images/4/1.jpg" alt="" />
            <p>
              策略競技，技壓群雄，《亂鬥魏蜀吳》競技需要運用策略戰術，利用兵種相克，好好琢磨排兵佈陣，只有熟知英雄技能特性，瞭解兵種互克搭配，才能再競技PK道路上越走越遠。<br />
              【競技衝刺 贏豐厚獎勵】<br />
              主公每日有五次和其他玩家挑戰的機會，若還想挑戰，可以消耗元寶購買挑戰次數。
            </p>
            <img src="../../assets/images/4/2.jpg" alt="" />
            <p>
              點擊“挑戰”，即可進入戰鬥頁面，玩家可以提前佈置合理陣容後再進行挑戰。競技場Pk挑戰對手即可獲得積分，勝利加2分，失敗加1分。
              而且當日累積積分達到條件可以獲得銅錢和比武幣獎勵，每日積分累積越高獎勵越豐富。除了積分獎勵還有競技場排名獎勵和首次到達相應排名的獎勵。
              排名獎勵可以獲得元寶、銅錢、比武幣獎勵，排名越高獎勵越豐富。
              首次獎勵也是獎勵元寶和銅錢，首次到達的等級越高，獎勵越豐富。
            </p>
            <img src="../../assets/images/4/3.jpg" alt="" />
            <p>
              比武幣可以到競技商城購買裝備、武將升階材料、裝備淬煉材料等等，都是提升實力的必需品，如果沒有合適的，可以使用刷新券刷新商城。
            </p>
            <img src="../../assets/images/4/4.jpg" alt="" />
            <p>
              三國爭霸，善謀善勇者勝之，《亂鬥魏蜀吳》競技場即時真人PK，誰有機會問鼎？戰過才知道！天下精英彙聚于此，你是否能脫穎而出成為王者？如果你對自己的隊伍有信心，就來競技場用實力證明自己吧！
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRaw,
  onBeforeMount,
  onMounted,
  onUnmounted,
  toRefs,
  ref,
  nextTick,
  computed,
  watch,
  watchEffect
} from 'vue'
import Header from '@/components/header.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {
    Header
  },
  props: [],
  setup(props, emit) {
    const router = useRouter()
    const data = reactive({
      navList: [
        {
          id: 0,
          className: 'first',
          url: '/',
          active: false
        },
        {
          id: 1,
          className: 'second',
          url: '/news',
          active: true
        },
        {
          id: 2,
          className: 'three',
          url: '',
          active: false
        },
        {
          id: 3,
          className: 'four',
          url: '/storeCenter',
          active: false
        }
      ],
      showBtn: false
    })
    onMounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    onUnmounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    const handleBack = () => {
      router.push('/news')
    }
    const showbtn = () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

      if (scrollTop > 900) {
        data.showBtn = true
      } else {
        data.showBtn = false
      }
    }
    const backTop = () => {
      var timer = setInterval(function () {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop
        let ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 30)
    }
    const refData = toRefs(data)
    return {
      showbtn,
      backTop,
      handleBack,
      ...refData
    }
  }
})
</script>
<style scoped lang="less">
img {
  width: 100%;
  margin: 20px auto;
}
.container {
  width: 100%;
  height: 100%;
  background: #fff8e9;
  .head {
    width: 100%;
    height: 552px;
    background: url('../../assets/images/head1_01.jpg') no-repeat center;
  }
  .content {
    width: 100%;
    height: 3792px;
    background: url('../../assets/images/content22.jpg') no-repeat center;
    background-size: 100% 100%;
    overflow: hidden;
    .con_wrapper {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;

      .content_wrapper {
        width: 1200px;
        height: 100%;
        background: rgba(255, 248, 233, 0.7);
        padding-bottom: 30px;
        overflow: hidden;
        .text_wrapper {
          margin: 10px 96px;
          .title {
            font-size: 33px;
            color: #b08a47;
            text-align: center;
            margin: 42px auto 34px;
          }
          p {
            color: #b08a47;
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }
    .top {
      display: block;
      width: 50px;
      height: 50px;
      background: url('../../assets/images/top.png') no-repeat center;
      background-size: 100%;
      position: fixed;
      bottom: 100px;
      right: 40px;
    }
  }
}
</style>