<template>
  <div class="container">
    <Header :navList="navList" />
    <div class="content">
      <a href="javascript:void(0)" class="top" @click="backTop" v-if="showBtn"></a>
      <div class="con_wrapper">
        <a href="javascript:void(0)" class="back" @click="handleBack"></a>
        <div class="content_wrapper">
          <div class="text_wrapper">
            <div class="title">【亂鬥魏蜀吳】重溫經典百戰！征戰玩法詳解</div>
            <p>
              《亂鬥魏蜀吳》重溫著名三國戰役，馳騁熱血三國戰場，在這裡玩家可以體驗虎牢關之戰、赤壁之戰、華容道、漢中爭奪戰、夷陵之戰等著名戰役，讓玩家身臨其境，體驗征戰天下的快感。同時，通關這些劇情關卡可以獲得元寶、銅錢及各種寶石。<br />
              【進入方式】
            </p>
            <img src="../../assets/images/16/1.jpg" alt="" />
            <p>
              主公進入遊戲主介面後，右下角點擊“征戰”-“自動尋路”，即可進入征戰頁面。<br />
              【玩法內容】
            </p>
            <img src="../../assets/images/16/2.jpg" alt="" />
            <p>
              在征戰玩法中，會根據不同的戰役分為不同的章節，只有通關此章節的所有關卡才會開啟下一章節。<br />
              玩家進入章節劇情，關卡消耗5點體力值，點擊“挑戰”，經過一段劇情後，即可進行戰鬥。通關後，可以直接去奇遇中進行掃蕩，獲得關卡掉落資源。<br />
              【戰前準備】<br />
              主公們在進入關卡之前，需要首先進行排兵佈陣，合理搭配武將，強大的陣容是獲勝的關鍵！點擊“佈陣”，可以進行武將陣容搭配。
            </p>
            <img src="../../assets/images/16/3.jpg" alt="" />
            <p>
              【通關獎勵】<br />
              關卡掉落經驗書、銅錢和各種寶石，每個關卡獎勵不同，隨著通關章節的增加，獎勵也是越來越豐富的。經驗書可以用於提升武將等級，銅錢用於購買物品，寶石是武將的升星材料。<br />
              《亂鬥魏蜀吳》經典重現大家耳熟能詳的三國著名戰役，誠邀您的加入，快來遊戲和你喜歡的三國名將一起一路披荊斬棘，過關斬將，傲視群雄吧！
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRaw,
  onBeforeMount,
  onMounted,
  onUnmounted,
  toRefs,
  ref,
  nextTick,
  computed,
  watch,
  watchEffect
} from 'vue'
import Header from '@/components/header.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {
    Header
  },
  props: [],
  setup(props, emit) {
    const router = useRouter()
    const data = reactive({
      navList: [
        {
          id: 0,
          className: 'first',
          url: '/',
          active: false
        },
        {
          id: 1,
          className: 'second',
          url: '/news',
          active: true
        },
        {
          id: 2,
          className: 'three',
          url: '',
          active: false
        },
        {
          id: 3,
          className: 'four',
          url: '/storeCenter',
          active: false
        }
      ],
      showBtn: false
    })
    onMounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    onUnmounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    const handleBack = () => {
      router.push('/news')
    }
    const showbtn = () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

      if (scrollTop > 900) {
        data.showBtn = true
      } else {
        data.showBtn = false
      }
    }
    const backTop = () => {
      var timer = setInterval(function () {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop
        let ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 30)
    }
    const refData = toRefs(data)
    return {
      showbtn,
      backTop,
      handleBack,
      ...refData
    }
  }
})
</script>
<style scoped lang="less">
.container {
  width: 100%;
  height: 100%;
  background: #fff8e9;
  img {
    width: 100%;
    margin: 20px auto;
  }
  .head {
    width: 100%;
    height: 552px;
    background: url('../../assets/images/head1_01.jpg') no-repeat center;
  }
  .content {
    width: 100%;
    height: 3792px;
    background: url('../../assets/images/content22.jpg') no-repeat center;
    background-size: 100% 100%;
    overflow: hidden;
    .con_wrapper {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;

      .content_wrapper {
        width: 1200px;
        height: 100%;
        background: rgba(255, 248, 233, 0.7);
        padding-bottom: 30px;
        overflow: hidden;
        .text_wrapper {
          margin: 10px 96px;
          .title {
            font-size: 33px;
            color: #b08a47;
            text-align: center;
            margin: 42px auto 34px;
          }
          p {
            color: #b08a47;
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }
    .top {
      display: block;
      width: 50px;
      height: 50px;
      background: url('../../assets/images/top.png') no-repeat center;
      background-size: 100%;
      position: fixed;
      bottom: 100px;
      right: 40px;
    }
  }
}
</style>