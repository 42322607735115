<template>
  <div class="container">
    <Header :navList="navList" />
    <div class="content">
      <a href="javascript:void(0)" class="top" @click="backTop" v-if="showBtn"></a>
      <div class="con_wrapper">
        <a href="javascript:void(0)" class="back" @click="handleBack"></a>
        <div class="content_wrapper">
          <div class="text_wrapper">
            <div class="title">【亂鬥魏蜀吳】分析戰況對症下藥</div>
            <p>
              《亂鬥魏蜀吳》中，玩家可以自主陣容搭配，在PVE、PVP戰鬥中運籌帷幄，策略逆轉戰局。好的軍師必須要能夠學會分析局勢，在戰場上運籌帷幄，掌握戰鬥節奏，戰報尤為關鍵。《亂鬥魏蜀吳》擁有完善的戰報系統，記錄你的每一場戰鬥，在戰鬥中浴火重生。
            </p>
            <p>文字戰報</p>
            <p>
              戰鬥中的文字戰報，覆蓋整個遊戲的戰鬥中，看戰報是一個良好的習慣，《亂鬥魏蜀吳》把戰鬥戰報作為重要的功能，為了杜絕玩家戰鬥結束不明白輸贏點在哪裡，戰報包括戰鬥中的文字戰報、戰鬥結束後的文字戰報，方便玩家分析戰鬥的過程，針對性的提升武將各方面的屬性。
            </p>
            <img src="../../assets/images/5/1.jpg" alt="" />
            <p>
              好的主公要會分析戰報，戰鬥中，戰報資料即時生成並顯示，資料細化到每位元武將技能、傷害等細節上。根據文字戰報方面玩家分析輸贏的點，瞭解陣容搭配與武將技能的實戰效果，有針對性地去培養武將，定制更加明智的策略。<br />
              戰鬥結束後，還可以看到本次戰鬥的戰報，我方統計和敵方統計，直觀看到陣容中每個武將在本場戰鬥中的普攻傷害、技能傷害、治療效果及承受傷害，方便大家及時調整陣容！
            </p>
            <img src="../../assets/images/5/2.jpg" alt="" />
            <p>
              競技場<br />
              想與對手一較高低嗎？想展現自己的實力嗎？那就來競技場PK吧，《亂鬥魏蜀吳》設置校場排行榜，根據主公的競技實力進行排名，競技PK考驗的是主公的陣容搭配策略，競技系統中的戰報詳細記錄主公的競技成敗，通過戰報資料與戰鬥重播，玩家可以直觀的瞭解本次戰役中武將的實際戰鬥效果，然後根據資料分析及時的進行陣容調整。
              當你發起挑戰後，點擊戰報，然後可以看到戰報資訊，戰報記錄了競技排名、戰鬥對手、結果、開始時間等等，如果你點擊“戰報重播”，可以重播當時的戰鬥場景，每一封戰報，都能讓我們更清楚戰事情況，根據勝負，方便玩家推演戰局，及時調整策略。
            </p>
            <img src="../../assets/images/5/3.jpg" alt="" />
            <p>
              戰報系統可以讓你清楚的瞭解戰況，分析各個武將在戰鬥中發揮的作用，找出戰役失敗的關鍵原因，對症下藥為下次戰役做好準備！
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRaw,
  onBeforeMount,
  onMounted,
  onUnmounted,
  toRefs,
  ref,
  nextTick,
  computed,
  watch,
  watchEffect
} from 'vue'
import Header from '@/components/header.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {
    Header
  },
  props: [],
  setup(props, emit) {
    const router = useRouter()
    const data = reactive({
      navList: [
        {
          id: 0,
          className: 'first',
          url: '/',
          active: false
        },
        {
          id: 1,
          className: 'second',
          url: '/news',
          active: true
        },
        {
          id: 2,
          className: 'three',
          url: '',
          active: false
        },
        {
          id: 3,
          className: 'four',
          url: '/storeCenter',
          active: false
        }
      ],
      showBtn: false
    })
    onMounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    onUnmounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    const handleBack = () => {
      router.push('/news')
    }
    const showbtn = () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

      if (scrollTop > 900) {
        data.showBtn = true
      } else {
        data.showBtn = false
      }
    }
    const backTop = () => {
      var timer = setInterval(function () {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop
        let ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 30)
    }
    const refData = toRefs(data)
    return {
      showbtn,
      backTop,
      handleBack,
      ...refData
    }
  }
})
</script>
<style scoped lang="less">
img {
  width: 100%;
  margin: 20px auto;
}
.container {
  width: 100%;
  height: 100%;
  background: #fff8e9;
  .head {
    width: 100%;
    height: 552px;
    background: url('../../assets/images/head1_01.jpg') no-repeat center;
  }
  .content {
    width: 100%;
    height: 3792px;
    background: url('../../assets/images/content22.jpg') no-repeat center;
    background-size: 100% 100%;
    overflow: hidden;
    .con_wrapper {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;

      .content_wrapper {
        width: 1200px;
        height: 100%;
        background: rgba(255, 248, 233, 0.7);
        padding-bottom: 30px;
        overflow: hidden;
        .text_wrapper {
          margin: 10px 96px;
          .title {
            font-size: 33px;
            color: #b08a47;
            text-align: center;
            margin: 42px auto 34px;
          }
          p {
            color: #b08a47;
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }
    .top {
      display: block;
      width: 50px;
      height: 50px;
      background: url('../../assets/images/top.png') no-repeat center;
      background-size: 100%;
      position: fixed;
      bottom: 100px;
      right: 40px;
    }
  }
}
</style>