<template>
  <div class="container">
    <Header :navList="navList" />
    <div class="content">
      <a href="javascript:void(0)" class="top" @click="backTop" v-if="showBtn"></a>
      <div class="con_wrapper">
        <a href="javascript:void(0)" class="back" @click="handleBack"></a>
        <div class="content_wrapper">
          <div class="text_wrapper">
            <div class="title">【亂鬥魏蜀吳】裝備銘文加成實力倍增</div>
            <p>
              《亂鬥魏蜀吳》裝備銘文系統揭開面紗，銘文系統是提升角色屬性的重要管道，每個裝備銘刻後提升的屬性不同，不同的裝備需要消耗的銘刻石數目不同，裝備銘文系統可以提升主公實力，助你攻城掠地，趕快來看看具體玩法吧！
            </p>
            <img src="../../assets/images/23/1.jpg" alt="" />
            <p>
              銘刻石的獲取<br />
              銘刻需要消耗材料銘刻石，可以通過華容道掉落獲取銘刻石。除此之外玩家可以通過同盟、競技商城商店購買銘刻石，消耗銘刻石即可進行銘刻，銘文升至藍銀時即可解鎖銘文強化技能。
            </p>
            <img src="../../assets/images/23/2.jpg" alt="" />
            <p>
              銘文技能<br />
              銘文品質分為青銅，藍銀，紫金，赤鉑，紅鑽，每銘刻一次消耗一定的銘刻石，屬性也隨之提升，升級到藍銀時需要消耗銘刻石和高級銘刻石，品質升級至藍銀時解鎖銘文強化技能。
            </p>
            <img src="../../assets/images/23/3.jpg" alt="" />
            <p>不同的裝備解鎖銘文技能不同，升級到不同的品質可以解鎖相應的技能。</p>
            <img src="../../assets/images/23/4.jpg" alt="" />
            <p>《亂鬥魏蜀吳》銘文系統上線，新的提升實力途徑，想要戰勝對手趕緊對裝備進行銘刻吧！</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRaw,
  onBeforeMount,
  onMounted,
  onUnmounted,
  toRefs,
  ref,
  nextTick,
  computed,
  watch,
  watchEffect
} from 'vue'
import Header from '@/components/header.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {
    Header
  },
  props: [],
  setup(props, emit) {
    const router = useRouter()
    const data = reactive({
      navList: [
        {
          id: 0,
          className: 'first',
          url: '/',
          active: false
        },
        {
          id: 1,
          className: 'second',
          url: '/news',
          active: true
        },
        {
          id: 2,
          className: 'three',
          url: '',
          active: false
        },
        {
          id: 3,
          className: 'four',
          url: '/storeCenter',
          active: false
        }
      ],
      showBtn: false
    })
    onMounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    onUnmounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    const handleBack = () => {
      router.push('/news')
    }
    const showbtn = () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

      if (scrollTop > 900) {
        data.showBtn = true
      } else {
        data.showBtn = false
      }
    }
    const backTop = () => {
      var timer = setInterval(function () {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop
        let ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 30)
    }
    const refData = toRefs(data)
    return {
      showbtn,
      backTop,
      handleBack,
      ...refData
    }
  }
})
</script>
<style scoped lang="less">
.container {
  width: 100%;
  height: 100%;
  background: #fff8e9;
  img {
    width: 100%;
    margin: 20px auto;
  }
  .head {
    width: 100%;
    height: 552px;
    background: url('../../assets/images/head1_01.jpg') no-repeat center;
  }
  .content {
    width: 100%;
    height: 3792px;
    background: url('../../assets/images/content22.jpg') no-repeat center;
    background-size: 100% 100%;
    overflow: hidden;
    .con_wrapper {
      width: 1230px;
      margin: 0 auto;
      overflow: hidden;
      .back {
        display: block;
        width: 41px;
        height: 41px;
        background: url('../../assets/images/back_03.png') no-repeat center;
        background-size: 100%;
        margin-left: 68px;
        margin-top: 30px;
        margin-bottom: 23px;
      }
      .content_wrapper {
        width: 1230px;
        height: 100%;
        background: rgba(255, 248, 233, 0.7);
        padding-bottom: 30px;
        overflow: hidden;
        .text_wrapper {
          margin: 10px 96px;
          .title {
            font-size: 33px;
            color: #b08a47;
            text-align: center;
            margin: 42px auto 34px;
          }
          p {
            color: #b08a47;
            font-size: 23px;
            line-height: 32px;
          }
        }
      }
    }
    .top {
      display: block;
      width: 50px;
      height: 50px;
      background: url('../../assets/images/top.png') no-repeat center;
      background-size: 100%;
      position: fixed;
      bottom: 100px;
      right: 40px;
    }
  }
}
</style>