<template>
  <div class="container">
    <Header :navList="navList" />
    <div class="content">
      <a href="javascript:void(0)" class="top" @click="backTop" v-if="showBtn"></a>
      <div class="con_wrapper">
        <a href="javascript:void(0)" class="back" @click="handleBack"></a>
        <div class="content_wrapper">
          <div class="text_wrapper">
            <div class="title">【亂鬥魏蜀吳】華容道玩法詳解</div>
            <p>
              華容道是著名歷史地名，有曹操敗走華容道的典故，《亂鬥魏蜀吳》內置華容道玩法，全新版本華容道已經更新上線，每層通關後還有豐厚的獎勵哦，下面一起看看吧。
              華容道的敵人來自於其他玩家的競技場防守陣容。華容道每48小時刷新一次，每一層上方都有結束時間倒計時，刷新後所有怪物、獎勵和建築都將重新生成。<br />
              每一層華容道都有多個小怪，玩家可以選擇一個格子前進探索。
              玩家每達到一個新的格子，和這個格子處在同一列的其他格子將會被摧毀，選擇不同結果不同，所以，選擇前進路線請謹慎。
            </p>
            <img src="../../assets/images/17/1.jpg" alt="" />
            <p>
              每擊敗一個華容道守衛都可以獲得華容之憶、遺物寶箱、銅錢。<br />
              華容之憶：可以在華容道商店中使用，兌換相應的貨物。<br />
              擊敗怪物，可以獲得遺物寶箱，玩家可以隨機從幾個遺物中選取一個，不同的遺物具有各不相同的效果。
            </p>
            <img src="../../assets/images/17/2.jpg" alt="" />
            <p>
              遺物寶箱中隨機出現三個遺物效果，遺物是一種特殊物品，只有在華容道中生效和查看，不會對華容道外的戰鬥有效果，每次華容道重置後，獲得的遺物將會消失。<br />
              達到該層最後BOSS關卡，會有額外元寶和挑戰積分獎勵。
            </p>
            <img src="../../assets/images/17/3.jpg" alt="" />
            <p>
              挑戰積分：挑戰者的證明，從華容道中獲得，用於解鎖華容道通行證獎勵。<br />
              每層通關後，可以領取寶箱，裡面包含紅裝碎片和龍涎香。領取完畢就要選擇傳送門，迎接新的挑戰。<br />
              龍涎香：在華容道下方，點擊“回春”，進入妙手回春，可以消耗一個龍涎香復活所有英雄全部生命和能量。
            </p>
            <img src="../../assets/images/17/4.jpg" alt="" />
            <p>挑戰途中有機會遇商人，可以用元寶購買自己所需的物品，有機會獲得橙裝、銘刻石喲，四個物品都可以購買</p>
            <img src="../../assets/images/17/5.jpg" alt="" />
            <p>以上就是《亂鬥魏蜀吳》華容道全新玩法，希望對您有所幫助！</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRaw,
  onBeforeMount,
  onMounted,
  onUnmounted,
  toRefs,
  ref,
  nextTick,
  computed,
  watch,
  watchEffect
} from 'vue'
import Header from '@/components/header.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {
    Header
  },
  props: [],
  setup(props, emit) {
    const router = useRouter()
    const data = reactive({
      navList: [
        {
          id: 0,
          className: 'first',
          url: '/',
          active: false
        },
        {
          id: 1,
          className: 'second',
          url: '/news',
          active: true
        },
        {
          id: 2,
          className: 'three',
          url: '',
          active: false
        },
        {
          id: 3,
          className: 'four',
          url: '/storeCenter',
          active: false
        }
      ],
      showBtn: false
    })
    onMounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    onUnmounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    const handleBack = () => {
      router.push('/news')
    }
    const showbtn = () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

      if (scrollTop > 900) {
        data.showBtn = true
      } else {
        data.showBtn = false
      }
    }
    const backTop = () => {
      var timer = setInterval(function () {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop
        let ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 30)
    }
    const refData = toRefs(data)
    return {
      showbtn,
      backTop,
      handleBack,
      ...refData
    }
  }
})
</script>
<style scoped lang="less">
.container {
  width: 100%;
  height: 100%;
  background: #fff8e9;
  img {
    width: 100%;
    margin: 20px auto;
  }
  .head {
    width: 100%;
    height: 552px;
    background: url('../../assets/images/head1_01.jpg') no-repeat center;
  }
  .content {
    width: 100%;
    height: 3792px;
    background: url('../../assets/images/content22.jpg') no-repeat center;
    background-size: 100% 100%;
    overflow: hidden;
    .con_wrapper {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;

      .content_wrapper {
        width: 1200px;
        height: 100%;
        background: rgba(255, 248, 233, 0.7);
        padding-bottom: 30px;
        overflow: hidden;
        .text_wrapper {
          margin: 10px 96px;
          .title {
            font-size: 33px;
            color: #b08a47;
            text-align: center;
            margin: 42px auto 34px;
          }
          p {
            color: #b08a47;
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }
    .top {
      display: block;
      width: 50px;
      height: 50px;
      background: url('../../assets/images/top.png') no-repeat center;
      background-size: 100%;
      position: fixed;
      bottom: 100px;
      right: 40px;
    }
  }
}
</style>