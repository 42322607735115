<template>
  <div class="container">
    <div class="head">
      <Header :navList="navList" />
    </div>
    <div class="content">
      <h1>儲值中心</h1>
      <div class="content_wrapper">
        <div class="content_left">
          <a
            href="javascript:void(0)"
            :class="item.active ? 'btn active' : 'btn'"
            v-for="item in btnList"
            :key="item.id"
            @click="handleClick(item)"
            >{{ item.name }}</a
          >
        </div>
        <div class="content_center">
          <div id="form" v-if="show">
            <form>
              <div>
                <label>賬號ID：</label>
                <input type="text" v-model="formState.userId" />
              </div>
              <div>
                <label>伺服器：</label>
                <select v-model="formState.serverId">
                  <option value="1">1</option>
                  <option value="1">1</option>
                  <option value="1">1</option>
                  <option value="1">1</option>
                </select>
              </div>
              <div>
                <label>角色：</label>
                <select v-model="formState.roleId">
                  <option value="1">1</option>
                  <option value="1">1</option>
                  <option value="1">1</option>
                  <option value="1">1</option>
                </select>
              </div>
              <div>
                <label>儲值金額：</label>
                <select v-model="formState.money">
                  <option value="1">1</option>
                  <option value="1">1</option>
                  <option value="1">1</option>
                  <option value="1">1</option>
                </select>
              </div>
              <div>
                <label>儲值方式：</label>
                <div class="box">
                  <p><span>銀行轉賬</span><input class="radio" type="radio" name="payType" /></p>
                  <p><span>信用卡</span><input class="radio" type="radio" name="payType" /></p>
                  <p><span>電子支付</span><input class="radio" type="radio" name="payType" /></p>
                  <p><span>其他</span><input class="radio" type="radio" name="payType" /></p>
                </div>
              </div>
              <div>
                <label></label>
                <div class="tips_wrapper">
                  <p>注意：官網儲值需5~10分鐘到賬</p>
                  <p><input type="checkbox" />我同意<a href="javascript:void(0)">儲值服務協議</a></p>
                  <div class="submit">
                    <a href="javascript:void(0)" class="btn">儲值</a>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div id="search" v-else>
            <div class="search_head">
              <label>賬號ID：</label><input type="text" /> <a href="javascript:void(0)" class="search_submit">查詢</a>
            </div>
            <div class="table_wrapper">
              <ul>
                <li>訂單狀態</li>
                <li>下單時間</li>
                <li>訂單號</li>
                <li>伺服器</li>
                <li>角色名</li>
                <li>儲值方式</li>
                <li>儲值金額</li>
              </ul>
              <ul>
                <li>訂單狀態</li>
                <li>下單時間</li>
                <li>訂單號</li>
                <li>伺服器</li>
                <li>角色名</li>
                <li>儲值方式</li>
                <li>儲值金額</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="content_right" v-if="show">
          <div class="right_wrapper">
            <p><img src="../../assets/images/warn_04.png" alt="" /> <span>賬號ID查詢方法:</span></p>
            <p>
              1.游戲内點擊<br />
              2.進入個人中心<br />
              3.複製賬號ID<br />
              4.輸入正確ID即可， 選擇相應伺服器 和角色
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRaw,
  onBeforeMount,
  onMounted,
  onUnmounted,
  toRefs,
  ref,
  nextTick,
  computed,
  watch,
  watchEffect
} from 'vue'
import Header from '@/components/header.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {
    Header
  },
  props: [],
  setup(props, emit) {
    const router = useRouter()
    const data = reactive({
      navList: [
        {
          id: 0,
          className: 'first',
          url: '/',
          active: false
        },
        {
          id: 1,
          className: 'second',
          url: '/news',
          active: false
        },
        {
          id: 2,
          className: 'three',
          url: '',
          active: false
        },
        {
          id: 3,
          className: 'four',
          url: '/storeCenter',
          active: true
        }
      ],
      btnList: [
        {
          id: 0,
          name: '在線儲值',
          active: true
        },
        {
          id: 1,
          name: '儲值查詢',
          active: false
        }
      ],
      show: true,
      formState: {
        userId: null
      }
    })
    onMounted(() => {})
    onUnmounted(() => {})
    const handleClick = (list) => {
      data.show = !data.show
      data.btnList.map((item) => {
        if (item.id == list.id) {
          item.active = true
        } else {
          item.active = false
        }
      })
    }
    const refData = toRefs(data)
    return {
      handleClick,
      ...refData
    }
  }
})
</script>
<style scoped lang="less">
.container {
  width: 100%;
  height: 100%;
  background: #fff8e9;
  .head {
    width: 100%;
    height: 554px;
    background: url('../../assets/images/head3_01.jpg') no-repeat center;
  }
  .content {
    width: 100%;
    height: 1287px;
    background: url('../../assets/images/content3_02.jpg') no-repeat center;
    background-size: 100% 100%;
    overflow: hidden;
    padding-bottom: 100px;

    h1 {
      color: #b08a47;
      font-size: 33px;
      text-align: center;
      margin: 27px auto 32px;
    }
    .content_wrapper {
      width: 1200px;
      margin: 0 auto;
      // height: 100%;
      overflow: hidden;
      display: flex;
      .content_left,
      .content_right {
        width: 182px;
        height: 315px;
        background: linear-gradient(to bottom, #fff6de, #fffcf3);
        border-radius: 8px;
        box-shadow: 2px 2px 2px #fffcf3;
        overflow: hidden;
        .right_wrapper {
          margin: 30px 9px;
          p {
            font-size: 16px;
            color: #b08a47;
            line-height: 32px;
            span {
              font-size: 18px;
              font-weight: bold;
              // margin-left: 12px;
            }
            img {
              vertical-align: sub;
            }
          }
          p:nth-child(2) {
            margin-left: 12px;
          }
        }
      }
      .content_left {
        a:nth-child(1) {
          margin-top: 22px;
          margin-bottom: 8px;
        }
        .btn {
          display: block;
          width: 151px;
          height: 40px;
          line-height: 40px;
          font-size: 20px;
          color: #b08a47;
          background: url('../../assets/images/btn_04.png') no-repeat center;
          background-size: 100%;
          text-align: center;
          margin: 0 auto;
        }
        .active {
          color: #6a4f1f;
          background: url('../../assets/images/btn_active_04.png') no-repeat center;
        }
      }
      .content_right {
        margin-right: 3px;
      }
      .content_center {
        flex: 1;
        margin: 0 30px;
        border-radius: 8px;
        // height: 100%;
        background: linear-gradient(to bottom, #fff6de, #fffcf3);
        box-shadow: 2px 2px 2px #fffcf3;
        #form {
          form {
            margin: 40px 60px;
            div {
              display: flex;
              margin-bottom: 25px;
              .tips_wrapper {
                display: block;
                margin-top: -35px;
                p {
                  color: #c6b9a1;
                  font-size: 12px;
                  margin-bottom: 8px;
                  a {
                    margin-left: 10px;
                    color: #fb3838;
                  }
                }
                div {
                  a {
                    display: block;
                    width: 151px;
                    height: 40px;
                    line-height: 40px;
                    font-size: 20px;
                    color: #6a4f1f;
                    background: url('../../assets/images/btn_active_04.png') no-repeat center;
                    background-size: 100%;
                    text-align: center;
                    margin: 25px auto 40px;
                  }
                }
              }
              label {
                font-size: 18px;
                color: #b08a47;
                width: 90px;
              }
              input[type='text'],
              select {
                width: 424px;
                height: 26px;
                background: #c6b9a1;
                color: #fff;
                border: 1px solid #bb9a61;
              }
              select {
                background: #c6b9a1 url('../../assets/images/arrow_04.png') no-repeat right;
                padding-right: 18px;
              }
              .box {
                background: #c6b9a1;
                color: #fff;
                border: 1px solid #bb9a61;
                display: block;
                width: 163px;
                height: 119px;
                border-radius: 8px;
                p {
                  margin: 4px 15px;
                  span {
                    display: inline-block;
                    width: 110px;
                    font-size: 18px;
                  }
                }
              }
              /** 未被选中的样式*/
              input[type='checkbox'] {
                width: 12px;
                height: 12px;
                // margin: 0 4px 0 0;
                appearance: none;
                position: relative;
                background: #fff;
                border: 1px solid #9f8457;
                vertical-align: middle;
                margin-right: 8px;
                // padding-left: 12px;
              }
              input[type='checkbox']:checked::after {
                content: '✓';
                color: #9f8457;
                font-size: 12px;
                font-weight: bold;
                margin-bottom: 10px;
                position: absolute;
                top: -3px;
                left: 0;
              }
              /** 未被选中的样式*/
              input[type='radio'] {
                width: 16px;
                height: 16px;
                appearance: none;
                position: relative;
                background: #fff;
                border: 2px solid #9f8457;
                border-radius: 50%;
                vertical-align: middle;
                // padding-left: 12px;
              }

              /** 选中的样式*/
              input[type='radio']:checked::before {
                content: '';
                background: #9f8457;
                position: absolute;
                top: 25%;
                left: 25%;
                width: 50%;
                height: 50%;
                border: none;
                border-radius: 50%;
              }
            }
          }
        }
        #search {
          margin: 30px 58px;
          overflow: hidden;
          .search_head {
            display: flex;
            align-items: center;
            // justify-content: center;
            label {
              font-size: 18px;
            }
            input[type='text'] {
              width: 424px;
              height: 26px;
              background: #c6b9a1;
              color: #fff;
              border: 1px solid #bb9a61;
              margin: 0 30px 0 20px;
            }
            a {
              display: block;
              width: 151px;
              height: 40px;
              line-height: 40px;
              font-size: 20px;
              color: #6a4f1f;
              background: url('../../assets/images/btn_active_04.png') no-repeat center;
              background-size: 100%;
              text-align: center;
              // margin: 25px auto 40px;
            }
          }
          .table_wrapper {
            margin-top: 40px;
            ul {
              border-bottom: 1px solid #e8ddc6;
              border-left: 1px solid #e8ddc6;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              li {
                flex: 1;
                font-size: 16px;
                color: #c1b8a9;
                text-align: center;
                padding: 9px 0;
                border-top: 1px solid #e8ddc6;
                border-right: 1px solid #e8ddc6;
              }
            }
            ul:nth-child(1) {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}
</style>