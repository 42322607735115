<template>
  <div class="container">
    <Header :navList="navList" />
    <div class="content">
      <a href="javascript:void(0)" class="top" @click="backTop" v-if="showBtn"></a>
      <div class="con_wrapper">
        <a href="javascript:void(0)" class="back" @click="handleBack"></a>
        <div class="content_wrapper">
          <div class="text_wrapper">
            <div class="title">【亂鬥魏蜀吳】手遊重生有什麼用？祭壇重生技巧</div>
            <p>
              《亂鬥魏蜀吳》中，武將和裝備可以進行重生，一些萌新不清楚《亂鬥魏蜀吳》重生有什麼用，這裡就來介紹一下武將重生是怎麼玩的，以及武將重生的一些技巧，希望對您有所幫助。<br />
              先來說說重生的作用，剛開始玩《亂鬥魏蜀吳》的時候，前期可能招募不到品質高的武將，前期只能用其他武將來進行暫時過渡，而後期獲得自己想要的強力武將角色後，之前的遊戲就沒有用了，培養所用的突破丹、碎片、經驗書等材料可以通過重生進行返還，其中突破丹返還80%，其他材料是全部返還的，這樣我們就可以將這些返還的進階、覺醒材料都用於新的強力武將的培養上。
            </p>

            <img src="../../assets/images/12/1.jpg" alt="" />
            <p>
              除了武將，裝備也可以進行重生，若不想要這個裝備，可以通過重生裝備返全部材料並且重置到1級，培養裝備消耗的銅錢、南山精鐵、銘刻石等等都如數奉還，這些都是裝備培養急需的材料，可以繼續培養其他裝備，簡而言之，有了裝備重生，玩家對裝備的強化、淬煉無後顧之憂，裝備隨意培養更換，不用害怕走彎路。
            </p>
            <img src="../../assets/images/12/2.jpg" alt="" />
            <p>
              下面說說具體操作，我們在遊戲主介面左上角打開【祭壇】-【重生】，即可對沒有上陣的武將或裝備進行重生。<br />
              以上就是《亂鬥魏蜀吳》中重生系統玩法詳解，小夥伴們可以去探索一番哦。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRaw,
  onBeforeMount,
  onMounted,
  onUnmounted,
  toRefs,
  ref,
  nextTick,
  computed,
  watch,
  watchEffect
} from 'vue'
import Header from '@/components/header.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {
    Header
  },
  props: [],
  setup(props, emit) {
    const router = useRouter()
    const data = reactive({
      navList: [
        {
          id: 0,
          className: 'first',
          url: '/',
          active: false
        },
        {
          id: 1,
          className: 'second',
          url: '/news',
          active: true
        },
        {
          id: 2,
          className: 'three',
          url: '',
          active: false
        },
        {
          id: 3,
          className: 'four',
          url: '/storeCenter',
          active: false
        }
      ],
      showBtn: false
    })
    onMounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    onUnmounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    const handleBack = () => {
      router.push('/news')
    }
    const showbtn = () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

      if (scrollTop > 900) {
        data.showBtn = true
      } else {
        data.showBtn = false
      }
    }
    const backTop = () => {
      var timer = setInterval(function () {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop
        let ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 30)
    }
    const refData = toRefs(data)
    return {
      showbtn,
      backTop,
      handleBack,
      ...refData
    }
  }
})
</script>
<style scoped lang="less">
img {
  width: 100%;
  margin: 20px auto;
}
.container {
  width: 100%;
  height: 100%;
  background: #fff8e9;
  .head {
    width: 100%;
    height: 552px;
    background: url('../../assets/images/head1_01.jpg') no-repeat center;
  }
  .content {
    width: 100%;
    height: 1956px;
    background: url('../../assets/images/content2_02.jpg') no-repeat center;
    background-size: 100% 100%;
    overflow: hidden;
    .con_wrapper {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;

      .content_wrapper {
        width: 1200px;
        height: 100%;
        background: rgba(255, 248, 233, 0.7);
        padding-bottom: 30px;
        overflow: hidden;
        .text_wrapper {
          margin: 10px 96px;
          .title {
            font-size: 33px;
            color: #b08a47;
            text-align: center;
            margin: 42px auto 34px;
          }
          p {
            color: #b08a47;
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }
    .top {
      display: block;
      width: 50px;
      height: 50px;
      background: url('../../assets/images/top.png') no-repeat center;
      background-size: 100%;
      position: fixed;
      bottom: 100px;
      right: 40px;
    }
  }
}
</style>