<template>
  <div class="header">
    <div class="header_wrapper">
      <div class="logo">
        <img src="../assets/images/logo_02.png" alt="" />
      </div>
      <ul id="nav">
        <li :class="item.className" v-for="item in navList" :key="item.id">
          <a href="javascript:void(0)" :class="item.active ? 'active' : ''" @click="handleClick(item)"></a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRaw, onBeforeMount, onMounted, toRefs, ref, nextTick, computed, watch } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  props: {},
  props: ['navList'],
  setup: function (props, emit) {
    const router = useRouter()
    const data = reactive({
      navList: props.navList
    })
    const handleClick = (list) => {
      router.push(list.url)
      data.navList.map((item) => {
        if (item.id == list.id) {
          item.active = true
        } else {
          item.active = false
        }
      })
    }
    const refData = toRefs(data)
    return {
      handleClick,
      ...refData
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  width: 100%;
  height: 103px;
  border-bottom: 2px solid #ffe397;
  background: rgba(0, 0, 0, 0.6);
}
.header_wrapper {
  width: 1215px;
  margin: 0 auto;
  display: flex;
}
.header_wrapper .logo {
  width: 283px;
}
.header_wrapper .logo img {
  width: 100%;
}
.header_wrapper ul {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-top: -2px;
}
.header_wrapper ul li a {
  display: block;
  width: 233px;
  height: 102px;
}
.header_wrapper ul li.first a {
  background: url('../assets/images/nav1.png') no-repeat center;
  background-size: 100%;
}
.header_wrapper ul li.first a.active {
  background: url('../assets/images/nav1_ative.png') no-repeat center;
  background-size: 100%;
}
.header_wrapper ul li.second a {
  background: url('../assets/images/nav2.png') no-repeat center;
  background-size: 100%;
}
.header_wrapper ul li.second a.active {
  background: url('../assets/images/nav2_active.png') no-repeat center;
  background-size: 100%;
}
.header_wrapper ul li.three a {
  background: url('../assets/images/nav3.png') no-repeat center;
  background-size: 100%;
}
.header_wrapper ul li.three a.active {
  background: url('../assets/images/nav3_active.png') no-repeat center;
  background-size: 100%;
}
.header_wrapper ul li.four a {
  background: url('../assets/images/nav4.png') no-repeat center;
  background-size: 100%;
}
.header_wrapper ul li.four a.active {
  background: url('../assets/images/nav4_active.png') no-repeat center;
  background-size: 100%;
}
</style>
