<template>
  <div class="body-container" id="app">
    <transition>
      <router-view class="view"></router-view>
    </transition>
  </div>
</template>
<script>
export default {
  name: 'App',
  data() {
    return {}
  },
  methods: {},
  mounted() {
    document.title = '亂鬥魏蜀吳'
  }
}
</script>
<style lang="less">
@charset "utf-8";
/* CSS Document */
body,
ul,
ol,
dl,
li,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
p,
input {
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
  font-family: 'Microsoft YaHei';
}
img {
  border: none;
}
input,
button,
textarea,
select {
  *font-size: 100%;
  border: none;
  appearance: none;
  outline: none;
}
/*清除ie的默认选择框样式清除，隐藏下拉箭头*/
select::-ms-expand {
  display: none;
}

ul,
ol {
  list-style: none;
  padding: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
} /*默认a标签样式*/
a {
  text-decoration: none;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.clear {
  zoom: 1;
}
.clear:after {
  content: '';
  display: block;
  height: 0;
  visibility: visible;
  clear: both;
}
.boder_tl {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
.boder_tl td {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.boder_bl {
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
.boder_bl td {
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.boder_tr {
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.boder_tr td {
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
.boder_br {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.boder_br td {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
.txt_center {
  text-align: center;
} /*表格table和tr有边框*/
.boder_ltr_trborder {
  border: 1px solid #ccc;
  border-bottom: none;
}
.boder_ltr_trborder tr {
  border-bottom: 1px solid #ccc;
}
</style>
