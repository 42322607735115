<template>
  <div class="container">
    <Header :navList="navList" />
    <div class="content">
      <a href="javascript:void(0)" class="top" @click="backTop" v-if="showBtn"></a>
      <div class="con_wrapper">
        <a href="javascript:void(0)" class="back" @click="handleBack"></a>
        <div class="content_wrapper">
          <div class="text_wrapper">
            <div class="title">【亂鬥魏蜀吳】至尊競技玩法詳解</div>
            <p>
              《亂鬥魏蜀吳》中至尊競技是跨服限時玩法，不同伺服器玩家齊聚一堂，主公可以加入競技挑戰對手，獲得相應的段位，究竟誰能奪得第一名？一切拿實力說話。下面小編為大家介紹下系統的具體玩法，希望對您有所幫助！
            </p>
            <img src="../../assets/images/22/1.jpg" alt="" />
            <p>
              游戲玩法：<br />
              1. 活動進行時，每天有5次免費挑戰次數，每天淩晨5點刷新免費挑戰次數；<br />
              2. 每次挑戰需消耗1次挑戰次數，挑戰次數不足時可花費元寶購買挑戰次數；<br />
              3. 若挑戰勝利且對手段位比自己高則互換段位，否則段位不變；戰鬥超時則判守方勝利<br />
              首次達到指定段位可領取段位獎勵；<br />
              4. 活動結束後，根據結束時的段位元通過郵件發放段位獎勵，至尊段位玩家會獲得額外至尊排名獎勵。<br />
              游戲獎勵：<br />
              1. 挑戰成功後，玩家可以領取對應的段位獎勵和至尊獎勵。<br />
              2. 本賽季中，首次達到對應段位即可領取對應段位獎勵，包括銅錢、突破丹、魂石。<br />
              3. 活動結束時，至尊段位玩家額外獲得至尊排名獎勵，通過郵件發放，等級越高獎勵越豐富。
            </p>
            <img src="../../assets/images/22/2.jpg" alt="" />
            <p>以上就是至尊競技玩法，至尊獎勵可以獲得稀有資源喲，趕緊發起競技挑戰吧，希望對您有所幫助！</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRaw,
  onBeforeMount,
  onMounted,
  onUnmounted,
  toRefs,
  ref,
  nextTick,
  computed,
  watch,
  watchEffect
} from 'vue'
import Header from '@/components/header.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {
    Header
  },
  props: [],
  setup(props, emit) {
    const router = useRouter()
    const data = reactive({
      navList: [
        {
          id: 0,
          className: 'first',
          url: '/',
          active: false
        },
        {
          id: 1,
          className: 'second',
          url: '/news',
          active: true
        },
        {
          id: 2,
          className: 'three',
          url: '',
          active: false
        },
        {
          id: 3,
          className: 'four',
          url: '/storeCenter',
          active: false
        }
      ],
      showBtn: false
    })
    onMounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    onUnmounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    const handleBack = () => {
      router.push('/news')
    }
    const showbtn = () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

      if (scrollTop > 900) {
        data.showBtn = true
      } else {
        data.showBtn = false
      }
    }
    const backTop = () => {
      var timer = setInterval(function () {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop
        let ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 30)
    }
    const refData = toRefs(data)
    return {
      showbtn,
      backTop,
      handleBack,
      ...refData
    }
  }
})
</script>
<style scoped lang="less">
.container {
  width: 100%;
  height: 100%;
  background: #fff8e9;
  img {
    width: 100%;
    margin: 20px auto;
  }
  .head {
    width: 100%;
    height: 552px;
    background: url('../../assets/images/head1_01.jpg') no-repeat center;
  }
  .content {
    width: 100%;
    height: 1956px;
    background: url('../../assets/images/content2_02.jpg') no-repeat center;
    background-size: 100% 100%;
    overflow: hidden;
    .con_wrapper {
      width: 1220px;
      margin: 0 auto;
      overflow: hidden;
      .back {
        display: block;
        width: 41px;
        height: 41px;
        background: url('../../assets/images/back_03.png') no-repeat center;
        background-size: 100%;
        margin-left: 68px;
        margin-top: 30px;
        margin-bottom: 22px;
      }
      .content_wrapper {
        width: 1220px;
        height: 100%;
        background: rgba(255, 248, 233, 0.7);
        padding-bottom: 30px;
        overflow: hidden;
        .text_wrapper {
          margin: 10px 96px;
          .title {
            font-size: 33px;
            color: #b08a47;
            text-align: center;
            margin: 42px auto 34px;
          }
          p {
            color: #b08a47;
            font-size: 22px;
            line-height: 32px;
          }
        }
      }
    }
    .top {
      display: block;
      width: 50px;
      height: 50px;
      background: url('../../assets/images/top.png') no-repeat center;
      background-size: 100%;
      position: fixed;
      bottom: 100px;
      right: 40px;
    }
  }
}
</style>