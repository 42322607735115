<template>
  <div class="container">
    <Header :navList="navList" />
    <div class="content">
      <a href="javascript:void(0)" class="top" @click="backTop" v-if="showBtn"></a>
      <div class="title">
        <h1>遊戲攻略</h1>
        <span>攻略</span>
      </div>
      <ul>
        <li v-for="item in newsList" :key="item.id" @click="handleClick(item)">
          <div class="news_wrapper">
            <div class="news_left">攻略</div>
            <div class="news_right">
              <h3>{{ item.id }}{{ item.title }}</h3>
              <p>{{ item.content }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRaw,
  onBeforeMount,
  onMounted,
  onUnmounted,
  toRefs,
  ref,
  nextTick,
  computed,
  watch,
  watchEffect
} from 'vue'
import Header from '@/components/header.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {
    Header
  },
  props: [],
  setup(props, emit) {
    const router = useRouter()
    const data = reactive({
      navList: [
        {
          id: 0,
          className: 'first',
          url: '/',
          active: false
        },
        {
          id: 1,
          className: 'second',
          url: '/news',
          active: true
        },
        {
          id: 2,
          className: 'three',
          url: '',
          active: false
        },
        {
          id: 3,
          className: 'four',
          url: '/storeCenter',
          active: false
        }
      ],
      showBtn: false,
      newsList: [
        {
          id: 1,
          title: '【亂鬥魏蜀吳】策略博弈！兵種克制機制曝光',
          content:
            '將為魂，兵為器，群雄逐鹿，除了足智多謀的“軍師”，士兵也很重要的，《亂鬥魏蜀吳》為玩家打造出一個兵種相克、策略至上的兵種系統，四大兵種環環相扣，平衡各兵種作戰能力，合理利用兵種排兵佈陣，可以起到意想不到的效果喲！',
          url: '/second'
        },
        {
          id: 2,
          title: '【亂鬥魏蜀吳】大招放不出？問題出在這裡',
          content:
            '《亂鬥魏蜀吳》中要想取得勝利，有個不得不說的機制是怒氣機制，武將的大招爆發力強，積攢怒氣釋放必殺技輸出傷害是決定戰局的關鍵。那麼問題來了，為什麼我打了半天，一個大都放不出？下面就爲大家帶來《亂鬥魏蜀吳》當前版本中的怒氣回復機制，希望對你有所幫助！',
          url: '/three'
        },
        {
          id: 3,
          title: '【亂鬥魏蜀吳】巔峰對決！競技場玩法',
          content:
            '自古競爭出人才，想要拔得頭籌就來競技場PK，和天下英雄一較高下吧！競技場是《亂鬥魏蜀吳》中的即時PVP玩法，是一個絕對公平的戰場，激鬥天下群雄，排兵佈陣策略至上，巔峰對決一觸即發，只有合理搭配陣容才能擊敗對方。',
          url: '/four'
        },
        {
          id: 4,
          title: '【亂鬥魏蜀吳】分析戰況對症下藥',
          content:
            '《亂鬥魏蜀吳》中，玩家可以自主陣容搭配，在PVE、PVP戰鬥中運籌帷幄，策略逆轉戰局。好的軍師必須要能夠學會分析局勢，在戰場上運籌帷幄，掌握戰鬥節奏，戰報尤為關鍵。《亂鬥魏蜀吳》擁有完善的戰報系統，記錄你的每一場戰鬥，在戰鬥中浴火重生。',
          url: '/five'
        },
        {
          id: 6,
          title: '【亂鬥魏蜀吳】國王搶奪戰！萬人國戰火熱打響',
          content:
            '《亂鬥魏蜀吳》熱血國戰來襲，真實戰場再現亂世戰火烽煙，國王搶奪戰、萬人國戰火熱進行中，遊戲中國戰需要國王進行宣戰，宣戰後成員積極報名參加，攻城掠地，搶佔城池資源，下面就看具體操作玩法吧！',
          url: '/six'
        },
        {
          id: 7,
          title: '【亂鬥魏蜀吳】激情國戰玩法詳解',
          content:
            '開疆拓土，一呼百應，《亂鬥魏蜀吳》開啟多人線上國戰玩法，想要在遊戲中開疆擴土，智謀與實力很重要！鐵馬金戈蓄勢待發，百萬雄兵嚴陣以待，激情國戰一觸即發，以謀略和武力的配合逐步攻破村鎮、要塞、城市，展現實力，威震四方！',
          url: '/seven'
        },
        {
          id: 8,
          title: '【亂鬥魏蜀吳】將星覺醒風雲起！武將覺醒玩法',
          content:
            '《亂鬥魏蜀吳》新增武將覺醒玩法，武將覺醒需要消耗一定的碎片和覺醒石，那麼覺醒後武將到底會有怎麼樣的變化？下面一起來看看吧~',
          url: '/eight'
        },
        {
          id: 9,
          title: '【亂鬥魏蜀吳】匠心巨制！打造真實策略國戰體驗',
          content:
            '《亂鬥魏蜀吳》是一款集策略、養成、熱血PK於一體的輕度SLG休閒國戰卡牌游戲，從卡牌+策略入手，跳出了傳統三國策略遊戲的框架，除了武將的培養，更注重戰術策略，拼的是謀略，讓玩家體驗身臨三國戰場奮勇殺敵的爽快感。',
          url: '/nine'
        },
        {
          id: 10,
          title: '【亂鬥魏蜀吳】缺錢缺材料？每日試煉助你一臂之力',
          content:
            '策略的設定及兵種的搭配是《亂鬥魏蜀吳》核心玩法，遊戲中提供了各種試煉副本，需要靈活運用策略方能取勝，其中，每日試煉副本是獲得經驗書、書卷、銅錢的重要途徑，考慮上陣武將搭配佈陣，以保順利通關獲取獎勵，下面來看看每日試煉副本吧！',
          url: '/ten'
        },
        {
          id: 11,
          title: '【亂鬥魏蜀吳】勢力即實力！同盟會玩法詳解',
          content:
            '立於亂世，孤軍奮戰不可取，尋找志同道和的朋友共創霸業才是明智之舉，《亂鬥魏蜀吳》中，同勢力玩家集結，創建同盟會，互相征伐，佔領城鎮，勇搶國王，下面為大家介紹《亂鬥魏蜀吳》中同盟會玩法。',
          url: '/eleven'
        },
        {
          id: 12,
          title: '【亂鬥魏蜀吳】手遊重生有什麼用？祭壇重生技巧',
          content:
            '亂鬥魏蜀吳》中，武將和裝備可以進行重生，一些萌新不清楚《亂鬥魏蜀吳》重生有什麼用，這裡就來介紹一下武將重生是怎麼玩的，以及武將重生的一些技巧，希望對您有所幫助。',
          url: '/twelve'
        },
        {
          id: 13,
          title: '【亂鬥魏蜀吳】一秒讀懂武將系統',
          content:
            '爭奪天下，武將十分重要，在《亂鬥魏蜀吳》中，玩家率麾下的所有武將攻城掠地，爭霸天下，玩家可以通過主頁面“武將”系統進入，瞭解各個武將謀士的屬性，招募並對其進行升級升星，可以有效的幫助玩家提高隊伍的戰鬥力',
          url: '/thirteen'
        },
        {
          id: 14,
          title: '【亂鬥魏蜀吳】戰場瞬息萬變！體驗沉浸式戰鬥的魅力',
          content:
            '三國爭霸名將集結，沙場對陣激烈對，抗角逐最強王者，下面為大家介紹《亂鬥魏蜀吳》戰鬥玩法的魅力，名將陣前對抗，看準時機，指尖大招超神逆轉，秒殺敵軍，體驗殺敵制勝的快感！',
          url: '/fourteen'
        },
        {
          id: 15,
          title: '【亂鬥魏蜀吳】戰馬登場！助你縱橫疆場',
          content:
            '金戈鐵馬，氣吞萬里如虎，好的戰馬讓你縱橫疆場，所向披靡。《亂鬥魏蜀吳》戰馬系統曝光，每名上陣武將都可以攜帶一匹戰馬衝鋒陷陣，那麼戰馬怎麼獲得？都有哪些品質的戰馬呢？下面看看以下分享。',
          url: '/fifteen'
        },
        {
          id: 16,
          title: '【亂鬥魏蜀吳】重溫經典百戰！征戰玩法詳解',
          content:
            '亂鬥魏蜀吳》重溫著名三國戰役，馳騁熱血三國戰場，在這裡玩家可以體驗虎牢關之戰、赤壁之戰、華容道、漢中爭奪戰、夷陵之戰等著名戰役，讓玩家身臨其境，體驗征戰天下的快感。同時，通關這些劇情關卡可以獲得元寶、銅錢及各種寶石。',
          url: '/sixteen'
        },
        {
          id: 17,
          title: '【亂鬥魏蜀吳】華容道玩法詳解',
          content:
            '華容道是著名歷史地名，有曹操敗走華容道的典故，《亂鬥魏蜀吳》內置華容道玩法，全新版本華容道已經更新上線，每層通關後還有豐厚的獎勵哦，下面一起看看吧。華容道的敵人來自於其他玩家的競技場防守陣容。華容道每48小時刷新一次，每一層上方都有結束時間倒計時，刷新後所有怪物、獎勵和建築都將重新生成。',
          url: '/seventeen'
        },
        {
          id: 18,
          title: '【亂鬥魏蜀吳】太學院科技優先學習哪個？',
          content:
            '《亂鬥魏蜀吳》中太學院可以進行科技投入，對基礎屬性、兵種、國家等三方面進行屬性能力的加成及提升，主公們潛心學習科技從而大幅提升實力，不過前期書卷和元寶有限，不可能所有的科技都投入，下面分享下太學院科技學習攻略。',
          url: '/eighteen'
        },
        {
          id: 19,
          title: '【亂鬥魏蜀吳】武將羈絆覺醒最強BUFF',
          content:
            '無兄弟，不三國，《亂鬥魏蜀吳》三國武將再續亂世情誼，遊戲推出武將羈絆玩法，武將集結惺惺相惜，激發最強戰鬥力，陣容混搭無限制，征戰天下問鼎中原指日可待！',
          url: '/nineteen'
        },
        {
          id: 20,
          title: '【亂鬥魏蜀吳】戰車升星改裝玩法來襲',
          content:
            '《亂鬥魏蜀吳》除了武將陣容外，作戰工具也必不可少，全新戰車系統玩法上線，新增戰車升星、改裝玩法，讓你打造強力戰車制霸戰場，所向披靡！當主公等級達到7級解鎖戰車玩法，玩家主介面點擊【主公】-【戰車】，即可上陣戰車。',
          url: '/twenty'
        },
        {
          id: 21,
          title: '亂鬥魏蜀吳】戰力飆升不用愁！寶石系統玩法來襲',
          content: '《亂鬥魏蜀吳》寶石系統來襲，裝備鑲嵌寶石後屬性和戰力可大幅提升，那麼寶石該如何鑲嵌呢？',
          url: '/twentyOne'
        },
        {
          id: 22,
          title: '亂鬥魏蜀吳】至尊競技玩法詳解',
          content:
            '《亂鬥魏蜀吳》中至尊競技是跨服限時玩法，不同伺服器玩家齊聚一堂，主公可以加入競技挑戰對手，獲得相應的段位，究竟誰能奪得第一名？一切拿實力說話。下面小編為大家介紹下系統的具體玩法，希望對您有所幫助！',
          url: '/twentyTwo'
        },
        {
          id: 23,
          title: '【亂鬥魏蜀吳】裝備銘文加成實力倍增',
          content:
            '《亂鬥魏蜀吳》裝備銘文系統揭開面紗，銘文系統是提升角色屬性的重要管道，每個裝備銘刻後提升的屬性不同，不同的裝備需要消耗的銘刻石數目不同，裝備銘文系統可以提升主公實力，助你攻城掠地，趕快來看看具體玩法吧！',
          url: '/twentyThree'
        },
        {
          id: 0,
          title: '【亂鬥魏蜀吳】FAQ',
          content: '下載遊戲所需的網路環境是什麼？由於下載用戶端較大，建議在Wifi連網環境下進行下載。-遊戲掉線怎麼辦？',
          url: '/first'
        }
      ]
    })
    onMounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    onUnmounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    const handleClick = (list) => {
      router.push(list.url)
    }
    const showbtn = () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

      if (scrollTop > 900) {
        data.showBtn = true
      } else {
        data.showBtn = false
      }
    }
    const backTop = () => {
      var timer = setInterval(function () {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop
        let ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 30)
    }
    const refData = toRefs(data)
    return {
      handleClick,
      showbtn,
      backTop,
      ...refData
    }
  }
})
</script>
<style scoped lang="less">
.container {
  width: 100%;
  height: 100%;
  background: #fff8e9;
  .head {
    width: 100%;
    height: 552px;
    background: url('../../assets/images/head1_01.jpg') no-repeat center;
  }
  .content {
    width: 100%;
    height: 4509px;
    background: url('../../assets/images/yxbg.jpg') no-repeat center;
    background-size: 100% 100%;
    overflow: hidden;
    padding-bottom: 100px;
    .title {
      text-align: center;
      height: 130px;
      h1 {
        font-size: 33px;
        color: #b08a47;
        margin: 32px auto 40px;
      }
      span {
        font-size: 15px;
        color: #b08a47;
        padding-bottom: 25px;
        border-bottom: 2px solid #b08a47;
        margin-left: -100px;
      }
    }
    ul {
      width: 100%;
      height: 100%;
      //   margin-top: 156px;
      li {
        display: block;
        height: 166px;
        border-top: 1px solid #e5dac7;
        border-bottom: 1px solid #e5dac7;
        cursor: pointer;
        .news_wrapper {
          width: 800px;
          height: 100%;
          margin: 0 auto;
          display: flex;
          align-items: center;
          .news_left {
            font-size: 15px;
            color: #fff;
            background: #b08a47;
            border-radius: 32px;
            width: 72px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            margin-right: 20px;
            margin-bottom: 20px;
          }
          .news_right {
            flex: 1;
            h3 {
              font-size: 20px;
              color: #b08a47;
              margin-bottom: 20px;
            }
            p {
              font-size: 14px;
              color: #d1c4ad;
              width: 600px;
              overflow: hidden; //溢出隐藏
              white-space: nowrap; //禁止换行
              text-overflow: ellipsis; //...
            }
          }
        }
      }
      li:hover {
        background: #ede4d5;
      }
    }
    .top {
      display: block;
      width: 50px;
      height: 50px;
      background: url('../../assets/images/top.png') no-repeat center;
      background-size: 100%;
      position: fixed;
      bottom: 100px;
      right: 40px;
    }
  }
}
</style>