<template>
  <div class="container">
    <Header :navList="navList" />
    <div class="content">
      <a href="javascript:void(0)" class="top" @click="backTop" v-if="showBtn"></a>
      <div class="con_wrapper">
        <a href="javascript:void(0)" class="back" @click="handleBack"></a>
        <div class="content_wrapper">
          <div class="text_wrapper">
            <div class="title">【亂鬥魏蜀吳】戰車升星改裝玩法來襲</div>
            <p>
              《亂鬥魏蜀吳》除了武將陣容外，作戰工具也必不可少，全新戰車系統玩法上線，新增戰車升星、改裝玩法，讓你打造強力戰車制霸戰場，所向披靡！<br />
              當主公等級達到7級解鎖戰車玩法，玩家主介面點擊【主公】-【戰車】，即可上陣戰車。
            </p>
            <img src="../../assets/images/20/1.jpg" alt="" />
            <p>
              點擊“更換戰車”，可以查看全部戰車，游戲内為玩家提供了多種戰車，主公可以部署上陣已經解鎖的戰車，目前每輛戰車有三個技能，不同的戰車技能不同。<br />
              戰車獲取<br />
              玩家做劇情任務，完成章節所有任務有機會獲得戰車獎勵。<br />
              戰車升星
            </p>
            <img src="../../assets/images/20/2.jpg" alt="" />
            <p>
              遊戲中難免堆積多餘的戰車，因此主公們不妨精益求精、資源利用，對戰車就行升星，從而提升戰車技能，提升實力。重複獲取戰車轉成戰車碎片提升戰車星級，若主公重複獲得該戰車可自動轉換為60碎片，可以對該戰車進行升星，點擊升星即可，當前版本目前可以最多升至5星，每升級一星戰車技能都會提升。
            </p>
            <img src="../../assets/images/20/3.jpg" alt="" />
            <p>戰車改裝</p>
            <img src="../../assets/images/20/4.jpg" alt="" />
            <p>
              改裝通過消耗改裝石為戰車屬性加成，改裝後可使戰車的總兵力、攻擊力、防禦力等屬性大幅提升，消耗一定材料後戰車提升改裝階級解鎖改裝天賦。<br />
              右側可以看到改裝的進度，改裝每階的3段進度全滿可自動進階，每階對應的戰車天賦不同。<br />
              初級改裝石：增加總兵力。<br />
              中級改裝石：增加防禦力。<br />
              高級改裝石：增加攻擊力。<br />
              強弩戰車，制霸戰場，SLG國戰卡牌手游《亂鬥魏蜀吳》帶你回到烽火狼煙的三國戰場，開啟一段天下爭雄的亂世之旅!
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRaw,
  onBeforeMount,
  onMounted,
  onUnmounted,
  toRefs,
  ref,
  nextTick,
  computed,
  watch,
  watchEffect
} from 'vue'
import Header from '@/components/header.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {
    Header
  },
  props: [],
  setup(props, emit) {
    const router = useRouter()
    const data = reactive({
      navList: [
        {
          id: 0,
          className: 'first',
          url: '/',
          active: false
        },
        {
          id: 1,
          className: 'second',
          url: '/news',
          active: true
        },
        {
          id: 2,
          className: 'three',
          url: '',
          active: false
        },
        {
          id: 3,
          className: 'four',
          url: '/storeCenter',
          active: false
        }
      ],
      showBtn: false
    })
    onMounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    onUnmounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    const handleBack = () => {
      router.push('/news')
    }
    const showbtn = () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

      if (scrollTop > 900) {
        data.showBtn = true
      } else {
        data.showBtn = false
      }
    }
    const backTop = () => {
      var timer = setInterval(function () {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop
        let ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 30)
    }
    const refData = toRefs(data)
    return {
      showbtn,
      backTop,
      handleBack,
      ...refData
    }
  }
})
</script>
<style scoped lang="less">
.container {
  width: 100%;
  height: 100%;
  background: #fff8e9;
  img {
    width: 100%;
    margin: 20px auto;
  }
  .head {
    width: 100%;
    height: 552px;
    background: url('../../assets/images/head1_01.jpg') no-repeat center;
  }
  .content {
    width: 100%;
    height: 3792px;
    background: url('../../assets/images/content22.jpg') no-repeat center;
    background-size: 100% 100%;
    overflow: hidden;
    .con_wrapper {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;

      .content_wrapper {
        width: 1200px;
        height: 100%;
        background: rgba(255, 248, 233, 0.7);
        padding-bottom: 30px;
        overflow: hidden;
        .text_wrapper {
          margin: 10px 96px;
          .title {
            font-size: 33px;
            color: #b08a47;
            text-align: center;
            margin: 42px auto 34px;
          }
          p {
            color: #b08a47;
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }
    .top {
      display: block;
      width: 50px;
      height: 50px;
      background: url('../../assets/images/top.png') no-repeat center;
      background-size: 100%;
      position: fixed;
      bottom: 100px;
      right: 40px;
    }
  }
}
</style>