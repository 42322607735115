<template>
  <div class="container">
    <Header :navList="navList" />
    <div class="content">
      <a href="javascript:void(0)" class="top" @click="backTop" v-if="showBtn"></a>
      <div class="con_wrapper">
        <a href="javascript:void(0)" class="back" @click="handleBack"></a>
        <div class="content_wrapper">
          <div class="text_wrapper">
            <div class="title">【亂鬥魏蜀吳】大招放不出？問題出在這裡</div>
            <p>
              《亂鬥魏蜀吳》中要想取得勝利，有個不得不說的機制是怒氣機制，武將的大招爆發力強，積攢怒氣釋放必殺技輸出傷害是決定戰局的關鍵。那麼問題來了，為什麼我打了半天，一個大都放不出？下面就爲大家帶來《亂鬥魏蜀吳》當前版本中的怒氣回復機制，希望對你有所幫助！
            </p>
            <p>怒氣設定</p>
            <p>
              戰鬥中武將有生命值和怒氣值的設定，其中怒氣值與武將必殺技的釋放相關，怒氣加滿，大招釋放，關鍵時刻可以力挽狂瀾，不過注意不要被對面控制，如果被控，大招是無法釋放的。
              目前武將怒氣滿怒值為1000，怒氣條攢滿後，戰鬥頁面武將的頭像會發光，說明此時可釋放大招，可以手動釋放也可以設置自動釋放。在同一場戰鬥中，每個名武將能夠多次釋放大招。
              開啟【自動】戰鬥模式的情況下，武將的怒氣滿後會自動釋放大招；
              非【自動】戰鬥模式下，武將頭像發光，主公可自行點擊武將頭像釋放大招。
            </p>
            <img src="../../assets/images/3/1.jpg" alt="" />
            <p>怒氣回復機制</p>
            <p>除了部分武將的技能加持外，武將本身的回怒有以下方式：</p>
            <p>1. 普通攻擊可獲得一定怒氣；</p>
            <p>2. 受擊後可獲得一定怒氣；</p>
            <p>3. 隨戰鬥時間自然增長一定怒氣；</p>
            <p>4. 擊殺敵方武將可獲得一定怒氣。</p>
            <p>
              基於以上怒氣回復方式可以得出：攻擊頻率越高的武將和受擊次數越多的武將獲得怒氣就越高。
              僅靠武將自身的攻擊、受擊來回復怒氣回怒太慢，不滿足戰鬥需求，隊友為自己加怒或者吸掉敵方的怒氣，通過操控敵我雙方的大招釋放時機來左右戰局。
            </p>
            <p>怒氣控制技能</p>
            <p>
              控制怒氣的技能類型有三種：加怒、減怒。 加怒：加怒技能可以為我方武將快速補充怒氣、觸發大招。
              一次性回怒武將——劉備，直接為目標武將一秒鐘怒氣回滿。
              劉備技能【仁德載世】可以消耗自己的怒氣為己方攻擊力最高的武將怒氣回滿。
            </p>
            <img src="../../assets/images/3/2.jpg" alt="" />
            <p>
              持續型回怒——諸葛亮，持續數秒為我方武將回復一定的怒氣。<br />
              諸葛亮技能【奇門遁甲】每0.5秒使周圍隨機3名其他單位獲得陣法加持，友軍在奇門遁甲受到激勵，回復50怒氣並增強30%攻擊力，持續8秒，敵人在範圍內則會暈眩4秒。
            </p>
            <img src="../../assets/images/3/3.jpg" alt="" />
            <p>
              減怒：武將發動技能，減去敵方目標武將的一定怒氣。<br />
              舉個例子，諸葛亮被動技能【舌戰群雄】可以吸取目標怒氣，延遲敵方釋放大招的時間。
            </p>
            <img src="../../assets/images/3/4.jpg" alt="" />
            <p>
              戰鬥中武將如何快速地釋放出大招，這些點需要注意：<br />
              1、合理陣容站位，優化武將輸出環境；<br />
              2、讓武將打出更多的輸出； <br />3、盡可能避免武將被控制而無法攻擊的情況。
              <br />4、可以在陣容中上陣加怒武將。<br />
              以上就是《亂鬥魏蜀吳》中怒氣機制的介紹，瞭解了才能更好的在戰鬥中發揮實力~
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRaw,
  onBeforeMount,
  onMounted,
  onUnmounted,
  toRefs,
  ref,
  nextTick,
  computed,
  watch,
  watchEffect
} from 'vue'
import Header from '@/components/header.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {
    Header
  },
  props: [],
  setup(props, emit) {
    const router = useRouter()
    const data = reactive({
      navList: [
        {
          id: 0,
          className: 'first',
          url: '/',
          active: false
        },
        {
          id: 1,
          className: 'second',
          url: '/news',
          active: true
        },
        {
          id: 2,
          className: 'three',
          url: '',
          active: false
        },
        {
          id: 3,
          className: 'four',
          url: '/storeCenter',
          active: false
        }
      ],
      showBtn: false
    })
    onMounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    onUnmounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    const handleBack = () => {
      router.push('/news')
    }
    const showbtn = () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

      if (scrollTop > 900) {
        data.showBtn = true
      } else {
        data.showBtn = false
      }
    }
    const backTop = () => {
      var timer = setInterval(function () {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop
        let ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 30)
    }
    const refData = toRefs(data)
    return {
      showbtn,
      backTop,
      handleBack,
      ...refData
    }
  }
})
</script>
<style scoped lang="less">
img {
  width: 100%;
  margin: 20px auto;
}
.container {
  width: 100%;
  height: 100%;
  background: #fff8e9;
  .head {
    width: 100%;
    height: 552px;
    background: url('../../assets/images/head1_01.jpg') no-repeat center;
  }
  .content {
    width: 100%;
    height: 3792px;
    background: url('../../assets/images/content22.jpg') no-repeat center;
    background-size: 100% 100%;
    overflow: hidden;
    .con_wrapper {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;

      .content_wrapper {
        width: 1200px;
        height: 100%;
        background: rgba(255, 248, 233, 0.7);
        padding-bottom: 30px;
        overflow: hidden;
        .text_wrapper {
          margin: 10px 96px;
          .title {
            font-size: 33px;
            color: #b08a47;
            text-align: center;
            margin: 42px auto 34px;
          }
          p {
            color: #b08a47;
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }
    .top {
      display: block;
      width: 50px;
      height: 50px;
      background: url('../../assets/images/top.png') no-repeat center;
      background-size: 100%;
      position: fixed;
      bottom: 100px;
      right: 40px;
    }
  }
}
</style>