<template>
  <div class="container">
    <Header :navList="navList" />
    <div class="content">
      <a href="javascript:void(0)" class="top" @click="backTop" v-if="showBtn"></a>
      <div class="con_wrapper">
        <a href="javascript:void(0)" class="back" @click="handleBack"></a>
        <div class="content_wrapper">
          <div class="text_wrapper">
            <div class="title">【亂鬥魏蜀吳】國王搶奪戰！萬人國戰火熱打響</div>
            <p>
              《亂鬥魏蜀吳》熱血國戰來襲，真實戰場再現亂世戰火烽煙，國王搶奪戰、萬人國戰火熱進行中，遊戲中國戰需要國王進行宣戰，宣戰後成員積極報名參加，攻城掠地，搶佔城池資源，下面就看具體操作玩法吧！
            </p>
            <img src="../../assets/images/6/1.jpg" alt="" />
            <p>【爭奪皇位 誰與爭鋒】</p>
            <p>
              主公等級達到16級解鎖同盟，開啟“王座”玩法，每天22點后可以開始爭奪新一輪的儲君，隔天22點時，儲君將成爲新的國王。<br />
              【國王宣戰 熱血攻城】<br />
              選定國王後，就可以對敵對勢力宣戰了，戰前準備也很重要，提前動員同盟成員，國王、將軍等最好提前聯繫好本勢力同盟積極參與。<br />
              國戰分為戰前宣戰、部隊集結、攻城，先來看看宣戰，國王選擇一個敵對陣營進行宣戰，每日有1次宣戰搶奪城池的機會，具體時間如下：<br />
              宣戰時間：08:00-19:30<br />
              報名時間：08:00-19:50<br />
              戰鬥時間：20:00-20:30<br />
              宣戰方法<br />
              在主介面點擊征戰-霸業進入世界地圖，選擇要宣戰的城池，然後選中該城池，點擊“宣戰”即可宣戰。<br />
              報名集結<br />
              國王宣戰後會有全服通知，同勢力下的玩家可以報名參戰，在主介面點征戰-霸業進入勢力介面，點擊【國戰開啓】-選擇報名目標-點擊【前往】，就能去指定的位置，隨後點擊“報名”即可進入戰場。<br />
              進入戰場，兩軍對峙，玩家需要選擇合適的位置進行部隊駐紮，然後選擇兵種，主要有以下幾個兵種及技能：<br />
              盾兵——不動如山：戰鬥時間翻倍<br />
              象兵——其疾如風：戰鬥時間減半<br />
              醫療兵——侵略如火：對相鄰格正在交戰的友軍添加2%傷害加成<br />
              大刀兵——不動如山：戰鬥時間翻倍<br />
              槍兵——其徐如林：相鄰格有友軍槍兵時，自身獲得1%傷害加成，至多3%<br />
              騎兵——其疾如風：戰鬥時間減半<br />
              弓兵——侵略如火：對相鄰格正在交戰的友軍添加2%傷害加成<br />
            </p>
            <img src="../../assets/images/6/2.jpg" alt="" />
            <p>
              那麼該如何選擇兵種呢？<br />
              國戰可自由佈陣，根據敵方的佈陣來決定我們方的佈陣，還有雙方的戰力壓制情況來決定佈陣。<br />
              比如我方高戰有戰力優勢，那麼敵方高戰的位置直接上騎兵+2弓兵，直接滅掉就行了，如果相反就放盾兵陣一直拖時間，讓我方高戰殺敵。如果戰力差距不大，可以試試用三槍陣提升傷害增加勝率。<br />
              國戰每場戰鬥的結算時間都是不一樣的，對自己戰力很自信的高戰玩家一般選擇騎兵，減少作戰時間，增加殺敵數量。<br />
              低戰力玩家選擇輔助兵種，弓兵或者盾兵，弓兵可以給附近作戰的友軍增加傷害，盾兵可以拖時間，尤其是敵方高戰，拖住對方，讓對方無法正常發揮，槍兵適合3個一起走，發揮兵種最高的傷害。<br />
              【攻城掠地 硝煙彌漫】<br />
              玩家報名後可以離開當前介面，征戰開始也可以進入戰場觀戰，瞭解敵我雙方態勢，遊戲中會及時播報當前戰鬥情況，個人連斬超神全憑謀略。<br />
              若國戰勝利後，可以減少當前城池的城防值，城防值歸零即可攻下城池，一座城池可能需要兩場甚至更多國戰的勝利才能決定其歸屬，
              所以，國戰之前可以讓同盟成員對城池進行破壞，可以選擇火攻、重錘、石鎬等方式，減少城防值，為成功攻下城池作準備。
            </p>
            <img src="../../assets/images/6/3.jpg" alt="" />
            <p>
              國戰獎勵<br />
              國戰結束後，會通過郵件發放積分排行獎勵，包括元寶、銅錢等等。<br />
              征戰九州，感受燒腦策略！SLG國戰卡牌手游《亂鬥魏蜀吳》實現真正的萬人國戰，三國勢力鬥勇鬥謀，真實再現古代血性戰場，體驗超神秒殺的快感。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRaw,
  onBeforeMount,
  onMounted,
  onUnmounted,
  toRefs,
  ref,
  nextTick,
  computed,
  watch,
  watchEffect
} from 'vue'
import Header from '@/components/header.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {
    Header
  },
  props: [],
  setup(props, emit) {
    const router = useRouter()
    const data = reactive({
      navList: [
        {
          id: 0,
          className: 'first',
          url: '/',
          active: false
        },
        {
          id: 1,
          className: 'second',
          url: '/news',
          active: true
        },
        {
          id: 2,
          className: 'three',
          url: '',
          active: false
        },
        {
          id: 3,
          className: 'four',
          url: '/storeCenter',
          active: false
        }
      ],
      showBtn: false
    })
    onMounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    onUnmounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    const handleBack = () => {
      router.push('/news')
    }
    const showbtn = () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

      if (scrollTop > 900) {
        data.showBtn = true
      } else {
        data.showBtn = false
      }
    }
    const backTop = () => {
      var timer = setInterval(function () {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop
        let ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 30)
    }
    const refData = toRefs(data)
    return {
      showbtn,
      backTop,
      handleBack,
      ...refData
    }
  }
})
</script>
<style scoped lang="less">
img {
  width: 100%;
  margin: 20px auto;
}
.container {
  width: 100%;
  height: 100%;
  background: #fff8e9;
  .head {
    width: 100%;
    height: 552px;
    background: url('../../assets/images/head1_01.jpg') no-repeat center;
  }
  .content {
    width: 100%;
    height: 3792px;
    background: url('../../assets/images/content22.jpg') no-repeat center;
    background-size: 100% 100%;
    overflow: hidden;
    .con_wrapper {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;

      .content_wrapper {
        width: 1200px;
        height: 100%;
        background: rgba(255, 248, 233, 0.7);
        padding-bottom: 30px;
        overflow: hidden;
        .text_wrapper {
          margin: 10px 96px;
          .title {
            font-size: 33px;
            color: #b08a47;
            text-align: center;
            margin: 42px auto 34px;
          }
          p {
            color: #b08a47;
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }
    .top {
      display: block;
      width: 50px;
      height: 50px;
      background: url('../../assets/images/top.png') no-repeat center;
      background-size: 100%;
      position: fixed;
      bottom: 100px;
      right: 40px;
    }
  }
}
</style>