<template>
  <div class="container">
    <Header :navList="navList" />
    <div class="content">
      <a href="javascript:void(0)" class="top" @click="backTop" v-if="showBtn"></a>
      <div class="con_wrapper">
        <a href="javascript:void(0)" class="back" @click="handleBack"></a>
        <div class="content_wrapper">
          <div class="text_wrapper">
            <div class="title">【亂鬥魏蜀吳】戰力飆升不用愁！寶石系統玩法來襲</div>
            <p>
              《亂鬥魏蜀吳》寶石系統來襲，裝備鑲嵌寶石後屬性和戰力可大幅提升，那麼寶石該如何鑲嵌呢？<br />
              寶石的品質<br />
              寶石品質分為綠、藍、紫、紅、橙等等，等級越高品質越好，不同品質的裝備可以鑲嵌寶石的數目不同，目前每個紫色裝備可以鑲嵌三件寶個，橙色裝備鑲嵌四個，紅色裝備五個，鑲嵌寶石等級越高，增加的屬性也越高。
            </p>
            <img src="../../assets/images/21/1.jpg" alt="" />
            <p>
              寶石獲取途徑<br />
              目前，在三分天下活動中排名第一的國家每日可以獲得寶箱獎勵，不同的排名獎勵寶箱的個數不同，領取寶箱後，點擊征戰-霸業玩法，右下角點擊寶箱，即可獲得寶石獎勵。<br />
              寶石鑲嵌操作方法<br />
              1. 點擊主介面陣容-裝備按鈕<br />
              2. 點擊寶石按鈕，進入寶石鑲嵌頁面<br />
              3. 選擇武將和裝備部件，選擇鑲嵌的寶石<br />
              目前，背包中無法查看現有的寶石，只能同時系統鑲嵌時查看。<br />
              寶石合成<br />
              1. 進入寶石系統，點擊“寶石合成”按鈕，即可進入合成頁面。<br />
              2. 三顆同類型寶石可以合成一個更高級的寶石，比如三個6級生命寶石可以合成一個7級生命寶石等。
            </p>
            <img src="../../assets/images/21/2.jpg" alt="" />
            <p>裝備通過鑲嵌各種不同的寶石可以有效提升戰力，快來為你的裝備鑲嵌寶石吧！</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRaw,
  onBeforeMount,
  onMounted,
  onUnmounted,
  toRefs,
  ref,
  nextTick,
  computed,
  watch,
  watchEffect
} from 'vue'
import Header from '@/components/header.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {
    Header
  },
  props: [],
  setup(props, emit) {
    const router = useRouter()
    const data = reactive({
      navList: [
        {
          id: 0,
          className: 'first',
          url: '/',
          active: false
        },
        {
          id: 1,
          className: 'second',
          url: '/news',
          active: true
        },
        {
          id: 2,
          className: 'three',
          url: '',
          active: false
        },
        {
          id: 3,
          className: 'four',
          url: '/storeCenter',
          active: false
        }
      ],
      showBtn: false
    })
    onMounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    onUnmounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    const handleBack = () => {
      router.push('/news')
    }
    const showbtn = () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

      if (scrollTop > 900) {
        data.showBtn = true
      } else {
        data.showBtn = false
      }
    }
    const backTop = () => {
      var timer = setInterval(function () {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop
        let ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 30)
    }
    const refData = toRefs(data)
    return {
      showbtn,
      backTop,
      handleBack,
      ...refData
    }
  }
})
</script>
<style scoped lang="less">
.container {
  width: 100%;
  height: 100%;
  background: #fff8e9;
  img {
    width: 100%;
    margin: 20px auto;
  }
  .head {
    width: 100%;
    height: 552px;
    background: url('../../assets/images/head1_01.jpg') no-repeat center;
  }
  .content {
    width: 100%;
    height: 1956px;
    background: url('../../assets/images/content2_02.jpg') no-repeat center;
    background-size: 100% 100%;
    overflow: hidden;
    .con_wrapper {
      width: 1210px;
      margin: 0 auto;
      overflow: hidden;
      .back {
        display: block;
        width: 41px;
        height: 41px;
        background: url('../../assets/images/back_03.png') no-repeat center;
        background-size: 100%;
        margin-left: 68px;
        margin-top: 30px;
        margin-bottom: 21px;
      }
      .content_wrapper {
        width: 1210px;
        height: 100%;
        background: rgba(255, 248, 233, 0.7);
        padding-bottom: 30px;
        overflow: hidden;
        .text_wrapper {
          margin: 10px 96px;
          .title {
            font-size: 33px;
            color: #b08a47;
            text-align: center;
            margin: 42px auto 34px;
          }
          p {
            color: #b08a47;
            font-size: 21px;
            line-height: 32px;
          }
        }
      }
    }
    .top {
      display: block;
      width: 50px;
      height: 50px;
      background: url('../../assets/images/top.png') no-repeat center;
      background-size: 100%;
      position: fixed;
      bottom: 100px;
      right: 40px;
    }
  }
}
</style>