<template>
  <div class="container">
    <Header :navList="navList" />
    <div class="content">
      <a href="javascript:void(0)" class="top" @click="backTop" v-if="showBtn"></a>
      <div class="con_wrapper">
        <a href="javascript:void(0)" class="back" @click="handleBack"></a>
        <div class="content_wrapper">
          <div class="text_wrapper">
            <div class="title">【亂鬥魏蜀吳】戰馬登場！助你縱橫疆場</div>
            <p>
              金戈鐵馬，氣吞萬里如虎，好的戰馬讓你縱橫疆場，所向披靡。《亂鬥魏蜀吳》戰馬系統曝光，每名上陣武將都可以攜帶一匹戰馬衝鋒陷陣，那麼戰馬怎麼獲得？都有哪些品質的戰馬呢？下面看看以下分享。<br />
              戰馬品質<br />
              戰馬的品質分為綠、藍、紫、橙，品質依次遞增，品質越高，屬性越好，同樣品質的戰馬屬性不同，有的加總兵力，有的加防禦力，還有的加攻擊力等等，玩家可以選擇合適的戰馬簽約。需要注意，僅僅只有橙色戰馬才擁有戰馬技能哦~
            </p>
            <img src="../../assets/images/15/1.jpg" alt="" />
            <p>
              戰馬獲取<br />
              玩家在主介面點擊“陣容”，選擇武將，右側點擊“坐騎”選擇“+”進入坐騎頁面，想要召喚戰馬，直接進入馬廄即可。<br />
              1、每日有一定量的普通狩獵次數，直接點擊召喚即可獲得戰馬。馬廄等級越高，免費儲存普通飼料上限增加，免費恢復飼料的時間減少。<br />
              2、選擇普通飼料，買幾次飼料就有幾次普通狩獵次數，再點擊召喚，可以獲得綠色或藍色戰馬。<br />
              3、選擇高級飼料，購買幾次高級飼料就有幾次高級召喚次數，再點擊召喚，可以獲得紫色及以上戰馬。<br />
              戰馬培養<br />
              戰馬天賦獲取<br />
              攜帶不同的屬性的天賦，用於提升戰馬屬性，首先，每天開花費銅錢進行戰馬召喚，召喚到品質低的戰馬可以直接去祭壇進行戰馬分解，分解可以獲得戰馬精魄、戰馬天賦經驗和各種品質的天賦。
            </p>
            <img src="../../assets/images/15/2.jpg" alt="" />
            <p>獲得戰馬精魄後，可以去天賦背包兌換天賦，為匹馬上架所需天賦，增加實力。</p>
            <img src="../../assets/images/15/3.jpg" alt="" />
            <p>
              戰馬天賦強化<br />
              天賦屬性有很多種：防禦力、攻擊力、總兵力、抗暴、暴擊、法術增傷等等，選擇合適的給戰馬，天賦可以進行強化，提升自身實力，強化消耗戰馬天賦經驗，通過戰馬天賦經驗獲得。
            </p>
            <img src="../../assets/images/15/4.jpg" alt="" />
            <p>寶馬配英雄，坐騎能提升主公的戰力，快來《亂鬥魏蜀吳》，為自己的武將配上吧~</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRaw,
  onBeforeMount,
  onMounted,
  onUnmounted,
  toRefs,
  ref,
  nextTick,
  computed,
  watch,
  watchEffect
} from 'vue'
import Header from '@/components/header.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {
    Header
  },
  props: [],
  setup(props, emit) {
    const router = useRouter()
    const data = reactive({
      navList: [
        {
          id: 0,
          className: 'first',
          url: '/',
          active: false
        },
        {
          id: 1,
          className: 'second',
          url: '/news',
          active: true
        },
        {
          id: 2,
          className: 'three',
          url: '',
          active: false
        },
        {
          id: 3,
          className: 'four',
          url: '/storeCenter',
          active: false
        }
      ],
      showBtn: false
    })
    onMounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    onUnmounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    const handleBack = () => {
      router.push('/news')
    }
    const showbtn = () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

      if (scrollTop > 900) {
        data.showBtn = true
      } else {
        data.showBtn = false
      }
    }
    const backTop = () => {
      var timer = setInterval(function () {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop
        let ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 30)
    }
    const refData = toRefs(data)
    return {
      showbtn,
      backTop,
      handleBack,
      ...refData
    }
  }
})
</script>
<style scoped lang="less">
.container {
  width: 100%;
  height: 100%;
  background: #fff8e9;
  img {
    width: 100%;
    margin: 20px auto;
  }
  .head {
    width: 100%;
    height: 552px;
    background: url('../../assets/images/head1_01.jpg') no-repeat center;
  }
  .content {
    width: 100%;
    height: 3792px;
    background: url('../../assets/images/content22.jpg') no-repeat center;
    background-size: 100% 100%;
    overflow: hidden;
    .con_wrapper {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;

      .content_wrapper {
        width: 1200px;
        height: 100%;
        background: rgba(255, 248, 233, 0.7);
        padding-bottom: 30px;
        overflow: hidden;
        .text_wrapper {
          margin: 10px 96px;
          .title {
            font-size: 33px;
            color: #b08a47;
            text-align: center;
            margin: 42px auto 34px;
          }
          p {
            color: #b08a47;
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }
    .top {
      display: block;
      width: 50px;
      height: 50px;
      background: url('../../assets/images/top.png') no-repeat center;
      background-size: 100%;
      position: fixed;
      bottom: 100px;
      right: 40px;
    }
  }
}
</style>