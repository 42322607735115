<template>
  <div class="container">
    <Header :navList="navList" />
    <div class="content">
      <a href="javascript:void(0)" class="top" @click="backTop" v-if="showBtn"></a>
      <div class="con_wrapper">
        <a href="javascript:void(0)" class="back" @click="handleBack"></a>
        <div class="content_wrapper">
          <div class="text_wrapper">
            <div class="title">【亂鬥魏蜀吳】勢力即實力！同盟會玩法詳解</div>
            <p>
              立於亂世，孤軍奮戰不可取，尋找志同道和的朋友共創霸業才是明智之舉，《亂鬥魏蜀吳》中，同勢力玩家集結，創建同盟會，互相征伐，佔領城鎮，勇搶國王，下面為大家介紹《亂鬥魏蜀吳》中同盟會玩法。<br />
              【玩法】<br />
              玩家在主介面點擊【同盟】進入同盟會玩法，可以選擇加入一個強大的同盟，也可以自己創建一個同盟自己當盟主。
            </p>
            <img src="../../assets/images/11/1.jpg" alt="" />
            <p>同盟會成員每日可捐獻五次，捐獻可以獲得不同數目的貢獻幣，它是同盟商城的指定貨幣，可以購買物品。</p>
            <img src="../../assets/images/11/2.jpg" alt="" />
            <p>
              同盟商城中，玩家可以消耗同盟貢獻幣購買書卷、銅錢、經驗書等物品，同盟貢獻幣可以通過同盟捐獻和同盟副本首領寶箱獲得。<br />
              【成員】
            </p>
            <img src="../../assets/images/11/3.jpg" alt="" />
            <p>
              盟主是同盟會的老大，擁有最高許可權，可以轉讓職位，任命副盟主、元老，或者把玩家移除出同盟。<br />
              【同盟活動】
            </p>
            <img src="../../assets/images/11/4.jpg" alt="" />
            <p>
              同盟會內置活動，有同盟副本和群雄逐鹿，可以每日進行挑戰。<br />
              同盟副本玩法：<br />
              1. 每個人一天只能挑戰4次<br />
              2. 每天早上5點刷新重置次數<br />
              3. 擊敗關卡首領後，全同盟成員可領取通關寶箱<br />
              4. 同盟副本從第一次發起進攻倒計時，七天后重置次數與獎勵<br />
              群雄逐鹿玩法：<br />
              1. 每週一5點開啓，週日24點結束<br />
              2. 通過佔領據點后，挂機獲得大量元寶、銅錢與積分獎勵<br />
              3. 根據積分排名，在週日24點結束後，結算同盟獎勵和個人獎勵<br />
              4. 據點分爲3個等級，由高到低為：城塞、軍營、營寨<br />
              5. 每日隨機一個據點發生豐產，時間爲當日5點至次日5點，豐產效果為當前據點產量+50%<br />
              6. 當一個據點中的所有駐扎點都是同一個同盟駐扎時，此據點判定為被該同盟獨佔，被佔領的據點產量+30%<br />
              7. 攻佔據點會消耗精力與攻擊令，防守據點會消耗精力，戰鬥勝利消耗20點精力，戰鬥失敗消耗全部精力
            </p>
            <img src="../../assets/images/11/5.jpg" alt="" />
            <p>
              【同盟建築】<br />
              同盟建築設有軍機營、軍舍、徵兵處、軍營等建築，每日可以使用元寶或銅錢進行捐贈，每日的捐贈次數有限，每個建築都可以加強對應兵種的兵力，同盟建築等級無法超過同盟等級。
            </p>
            <img src="../../assets/images/11/6.jpg" alt="" />
            <p>攻城掠地，割據一方，想要帶領好兄弟一起稱霸天下嗎，就來《亂鬥魏蜀吳》，開啟自己的稱王之路吧！</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRaw,
  onBeforeMount,
  onMounted,
  onUnmounted,
  toRefs,
  ref,
  nextTick,
  computed,
  watch,
  watchEffect
} from 'vue'
import Header from '@/components/header.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {
    Header
  },
  props: [],
  setup(props, emit) {
    const router = useRouter()
    const data = reactive({
      navList: [
        {
          id: 0,
          className: 'first',
          url: '/',
          active: false
        },
        {
          id: 1,
          className: 'second',
          url: '/news',
          active: true
        },
        {
          id: 2,
          className: 'three',
          url: '',
          active: false
        },
        {
          id: 3,
          className: 'four',
          url: '/storeCenter',
          active: false
        }
      ],
      showBtn: false
    })
    onMounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    onUnmounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    const handleBack = () => {
      router.push('/news')
    }
    const showbtn = () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

      if (scrollTop > 900) {
        data.showBtn = true
      } else {
        data.showBtn = false
      }
    }
    const backTop = () => {
      var timer = setInterval(function () {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop
        let ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 30)
    }
    const refData = toRefs(data)
    return {
      showbtn,
      backTop,
      handleBack,
      ...refData
    }
  }
})
</script>
<style scoped lang="less">
img {
  width: 100%;
  margin: 20px auto;
}
.container {
  width: 100%;
  height: 100%;
  background: #fff8e9;
  .head {
    width: 100%;
    height: 552px;
    background: url('../../assets/images/head1_01.jpg') no-repeat center;
  }
  .content {
    width: 100%;
    height: 4509px;
    background: url('../../assets/images/content.jpg') no-repeat center;
    background-size: 100% 100%;
    overflow: hidden;
    .con_wrapper {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;

      .content_wrapper {
        width: 1200px;
        height: 100%;
        background: rgba(255, 248, 233, 0.7);
        padding-bottom: 30px;
        overflow: hidden;
        .text_wrapper {
          margin: 10px 96px;
          .title {
            font-size: 33px;
            color: #b08a47;
            text-align: center;
            margin: 42px auto 34px;
          }
          p {
            color: #b08a47;
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }
    .top {
      display: block;
      width: 50px;
      height: 50px;
      background: url('../../assets/images/top.png') no-repeat center;
      background-size: 100%;
      position: fixed;
      bottom: 100px;
      right: 40px;
    }
  }
}
</style>