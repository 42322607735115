<template>
  <div class="container">
    <Header :navList="navList" />
    <div class="content">
      <a href="javascript:void(0)" class="top" @click="backTop" v-if="showBtn"></a>
      <div class="con_wrapper">
        <a href="javascript:void(0)" class="back" @click="handleBack"></a>
        <div class="content_wrapper">
          <div class="text_wrapper">
            <div class="title">【亂鬥魏蜀吳】戰場瞬息萬變！體驗沉浸式戰鬥的魅力</div>
            <p>
              三國爭霸名將集結，沙場對陣激烈對，抗角逐最強王者，下面為大家介紹《亂鬥魏蜀吳》戰鬥玩法的魅力，名將陣前對抗，看準時機，指尖大招超神逆轉，秒殺敵軍，體驗殺敵制勝的快感！<br />
              沉浸式戰鬥<br />
              兩軍對峙，戰場瞬息萬變，超強大招手動靈活釋放，增加戰鬥體驗感，在《亂鬥魏蜀吳》兩軍對戰過程中，攻擊累積怒氣值，怒氣值積滿後就可釋放必殺神技，關鍵時刻超強殺傷力給予敵方致命一擊，從而扭轉戰局。靈活華麗的必殺技能，氣勢恢宏的熱血戰場，這種沉浸式的戰鬥玩法，讓玩家更有一種親臨戰場指揮的爽快感！
            </p>
            <img src="../../assets/images/14/1.jpg" alt="" />
            <p>
              戰鬥畫面<br />
              《亂鬥魏蜀吳》對於戰鬥場景的製作花費了大量的精力。戰鬥佈局分為前後兩排，佈局清晰直觀，在戰鬥畫面細緻到每個士兵，我們可以看到士兵整齊排列，每個個體攻擊的動作都看得很清楚，主將專屬技能炫酷華麗，橫掃敵軍，輕鬆克敵制勝。
            </p>
            <img src="../../assets/images/14/2.jpg" alt="" />
            <p>
              戰報系統<br />
              看戰報是一個良好的習慣，因為通過戰報資料與戰鬥重播，玩家可以直觀的瞭解本次戰役中武將的實際戰鬥效果。《亂鬥魏蜀吳》把戰鬥戰報作為重要的功能，為了杜絕玩家戰鬥結束不明白輸贏點在哪裡，戰報包括戰鬥中的文字戰報、戰鬥結束後的文字戰報，方便玩家分析戰鬥的過程，針對性的提升武將各方面的屬性。
            </p>
            <img src="../../assets/images/14/3.jpg" alt="" />
            <p>
              戰報資料即時生成並顯示，資料細化到每位元武將技能、傷害等細節上。根據文字戰報方面玩家分析輸贏的點，瞭解陣容搭配與武將技能的實戰效果，有針對性地去培養武將，定制更加明智的策略。
              沉浸式戰鬥、技能釋放、詳細的戰報系統等對戰設計，使得玩家能夠輕鬆掌握戰場軍情態勢，從而讓玩家有親臨戰場戰鬥的快感，還在等什麼，趕緊預約遊戲，來《亂鬥魏蜀吳》體驗一番吧！
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRaw,
  onBeforeMount,
  onMounted,
  onUnmounted,
  toRefs,
  ref,
  nextTick,
  computed,
  watch,
  watchEffect
} from 'vue'
import Header from '@/components/header.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {
    Header
  },
  props: [],
  setup(props, emit) {
    const router = useRouter()
    const data = reactive({
      navList: [
        {
          id: 0,
          className: 'first',
          url: '/',
          active: false
        },
        {
          id: 1,
          className: 'second',
          url: '/news',
          active: true
        },
        {
          id: 2,
          className: 'three',
          url: '',
          active: false
        },
        {
          id: 3,
          className: 'four',
          url: '/storeCenter',
          active: false
        }
      ],
      showBtn: false
    })
    onMounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    onUnmounted(() => {
      window.addEventListener('scroll', showbtn)
    })
    const handleBack = () => {
      router.push('/news')
    }
    const showbtn = () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

      if (scrollTop > 900) {
        data.showBtn = true
      } else {
        data.showBtn = false
      }
    }
    const backTop = () => {
      var timer = setInterval(function () {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop
        let ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 30)
    }
    const refData = toRefs(data)
    return {
      showbtn,
      backTop,
      handleBack,
      ...refData
    }
  }
})
</script>
<style scoped lang="less">
.container {
  width: 100%;
  height: 100%;
  background: #fff8e9;
  img {
    width: 100%;
    margin: 20px auto;
  }
  .head {
    width: 100%;
    height: 552px;
    background: url('../../assets/images/head1_01.jpg') no-repeat center;
  }
  .content {
    width: 100%;
    height: 3792px;
    background: url('../../assets/images/content22.jpg') no-repeat center;
    background-size: 100% 100%;
    overflow: hidden;
    .con_wrapper {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;

      .content_wrapper {
        width: 1200px;
        height: 100%;
        background: rgba(255, 248, 233, 0.7);
        padding-bottom: 30px;
        overflow: hidden;
        .text_wrapper {
          margin: 10px 96px;
          .title {
            font-size: 33px;
            color: #b08a47;
            text-align: center;
            margin: 42px auto 34px;
          }
          p {
            color: #b08a47;
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }
    .top {
      display: block;
      width: 50px;
      height: 50px;
      background: url('../../assets/images/top.png') no-repeat center;
      background-size: 100%;
      position: fixed;
      bottom: 100px;
      right: 40px;
    }
  }
}
</style>